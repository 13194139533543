.modal-backdrop {
  display: none;
}
.modal-open .modal {
  margin: 0 auto;
}
.modal-open {
  overflow-y: auto !important;
}

.modal.modal-bottom .modal-dialog {
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

/* Top and Bottom */

.modal.modal-bottom .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.modal.modal-bottom .modal-content {
  height: auto;
  overflow-y: auto;
  background-color: #006837;
}

.modal.modal-bottom .modal-body {
  padding: 15px 15px;
}

/* Bottom */

.modal.modal-bottom.fade .modal-dialog {
  bottom: -100%;
  -webkit-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  -o-transition: opacity 0.3s linear, bottom 0.3s ease-out;
  transition: opacity 0.3s linear, bottom 0.3s ease-out;
}

.modal.modal-bottom.fade.show .modal-dialog {
  bottom: 0;
}

.modal.modal-bottom.fade .modal-dialog {
  bottom: -100%;
}
.custom-close {
  width: 30px;
  height: 30px;
  background-color: #bdb0b0 !important;
  border-radius: 50%;
  opacity: 1 !important;
  padding-bottom: 10px !important;
}
.recent-news-btn {
  width: 150px;
  height: 40px;
  background-color: #f70f21;
  text-align: center;
  padding-top: 4px;
  font-family: SolaimanLipi;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: white;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}
.recent-news-btn a {
  font-family: SolaimanLipi;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: white;
}
.recent-news-title {
  display: flex;
  float: right;
}
.recent-news-title>button:hover {
  background-color: red!important;
}
.recent-news-title>button:active:focus {
  border-color: red;
  box-shadow:unset!important;
  background-color: red!important;
  outline:unset
}
.recent-news-title>.btn {
  box-shadow:unset!important;
}

.recent-news {
  padding-top: 4px;
  font-family: SolaimanLipi;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  color: white;
}

.recent-news:hover {
  padding-top: 4px;
  font-family: SolaimanLipi;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  color: red;
}
.custom-close-recent {
  width: 30px;
  height: 30px;
  background-color: #ffffff !important;
  border-radius: 50%;
  opacity: 1 !important;
  padding-bottom: 10px !important;
  padding: 3px 10.5px !important;
  margin-right: 10px;
  border: none !important;
  color: #f70f21;
}
.bottom-modal-content {
  border: none !important;
  margin-left: -1px;
}
@media (max-width: 764px) {
  .custom-close-recent {
    padding: 0px 10.5px !important;
  }
  .bottom-modal-content {
    border: none !important;
    margin-left: -1px;
  }
  .custom-close-recent {
    margin-right: 0px;
  }
  .recent-news-btn {
    width: 130px !important;
    height: 32px !important;
    padding-top: 1px !important;
    margin-bottom: 10px !important;
  }
  .recent-news-btn a {
    font-size: 16px !important;
  }
  .recent-news {
    font-size: 18px;
  }
  .modal.modal-bottom.fade.show .modal-dialog {
    bottom: -2px;
  }
}
