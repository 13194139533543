.confirm-box {
  position: fixed !important;
}
.confirm-box__actions > button:first-child {
  border: none;
  background-color: rgb(43, 195, 241);
}
.confirm-box__actions > button:last-child {
  border: none;
  background-color: red;
}
@media (max-width: 768px) and (min-width: 361px) {
  .confirm-box {
    left: 11% !important;
    top: 35% !important;
  }
}
