.wrapper {
  position: relative;
  border-radius: 5px;
  background-image: linear-gradient(to top, #accbee 0%, #e7f0fd 100%);
  overflow: hidden;
}

.wave {
  width: 1000px;
  height: 1025px;
  position: relative;
  top: -25%;
  left: 50%;
  margin-left: -500px;
  margin-top: -500px;
  border-radius: 35%;
  background: rgba(255, 255, 255, 0.75);
  animation: wave 15s infinite linear;
}
.em-place-body {
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.em-place-body img {
  width: 290px;
  height: 125px;
}
.placeholder-ads {
  background-color: #eeebeb;
  background: #eeebeb;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}
.placeholder-ads a {
  font-size: large;
}
@keyframes wave {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}
