@import url('https://fonts.maateen.me/solaiman-lipi/font.css');
title {
    font-family: 'SolaimanLipi', sans-serif;
}

/* Scrollbar */

::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(196, 196, 196);
    border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgb(196, 196, 196);
}

::-webkit-scrollbar-track {
    background-color: rgb(237, 237, 237);
}

/*
:root {
    --color-base:#006837;
    --color-black: black;
    --color-white: white;
    --color-red: red;
    --color-yellow: #f3bf05;
    --color-link: #006837;
    --color-link-aria: red;
    --color-border: #e5e5e5;
    --color-border-light: #e7e7e7;
    --color-h-search-send: #006837;
    --color-menu-bg: #006837;
    --color-header-menu-bg: #f81020;
    --color-header-menu-hover: #1a774b;
    --color-header-menu-active: #ec2028;
    --color-hero-news-body-border: #e5e5e5;
    --color-update-news-title-bg: #f1e8f6;
    --color-update-news-title-text: #7719aa;
    --color-home-category-news-title-bg: #e8f2fa;
    --color-details-news-tag-bg:#e6e6e6;
    --color-details-news-tag-text:#1879d0;
    --color-home-category-news-bg:linear-gradient(317deg, transparent 30px, #006837 31px);
    --color-top-h-border: #0000001a;
    --color-not-found-404-btn:#e5efea;
    --color-footer-fst:linear-gradient(90deg, #00542c 0%, #000f08 100%);
    --color-footer-3rd:linear-gradient(90deg, #00542c 0%, #000f08 100%);
    --color-footer-full-menu:linear-gradient(to bottom, white, rgba(0, 0, 0, 0)) 1 100%;
    --coolor-footer-dev-text:#00ff87;
    --color-header-top-bar-icon:rgba(17, 17, 17, 0.3);
    --color-top-menu-modal-body:#f2f2f2;
    --color-figcaption:#ffe1e1;
    --color-tv-style-shadow:#999;
    --color-calender-selected:#006837


} 
:root {
    --color-base:linear-gradient(90deg, #F70F21 0%, #290003 100%);
    --color-black: black;
    --color-white: white;
    --color-red: red;
    --color-yellow: #f3bf05;
    --color-link: #006837;
    --color-link-aria: red;
    --color-border: #e5e5e5;
    --color-border-light: #e7e7e7;
    --color-h-search-send: #CD2431;
    --color-menu-bg: linear-gradient(90deg, #F70F21 0%, #290003 100%);
    --color-header-menu-bg:linear-gradient(90deg, #F70F21 0%, #290003 100%);
    --color-header-menu-hover: #CD2431;
    --color-header-menu-active: #ec2028;
    --color-hero-news-body-border: #e5e5e5;
    --color-update-news-title-bg: #f1e8f6;
    --color-update-news-title-text: #F70F21;
    --color-home-category-news-title-bg: #FEE7E9;
    --color-details-news-tag-bg:#e6e6e6;
    --color-details-news-tag-text:#1879d0;
    --color-home-category-news-bg:linear-gradient(317deg, transparent 30px, #F70F21 31px);
    --color-top-h-border: #0000001a;
    --color-not-found-404-btn:#e5efea;
    --color-footer-fst:linear-gradient(90deg, #F70F21 0%, #290003 100%);
    --color-footer-3rd:linear-gradient(90deg, #F70F21 0%, #290003 100%);
    --color-footer-full-menu:linear-gradient(to bottom, white, rgba(0, 0, 0, 0)) 1 100%;
    --coolor-footer-dev-text:#CB335B;
    --color-header-top-bar-icon:rgba(17, 17, 17, 0.3);
    --color-top-menu-modal-body:#f2f2f2;
    --color-figcaption:#ffe1e1;
    --color-tv-style-shadow:#999;
    --color-calender-selected:red;

}
*/

/* 
:root {
    --color-base: linear-gradient(180deg, #f70f21 0%, #290003 100%);
    --color-black: black;
    --color-white: white;
    --color-red: red;
    --color-yellow: #f3bf05;
    --color-link: #006837;
    --color-link-aria: red;
    --color-border: #e5e5e5;
    --color-border-light: #e7e7e7;
    --color-h-search-send: #cd2431;
    --color-menu-bg: linear-gradient(90deg, #f70f21 0%, #290003 100%);
    --color-header-menu-bg: linear-gradient(180deg, #f70f21 0%, #290003 100%);
    --color-header-menu-hover: #cd2431;
    --color-header-menu-active: #ec2028;
    --color-hero-news-body-border: #e5e5e5;
    --color-update-news-title-bg: #f1e8f6;
    --color-update-news-title-text: #f70f21;
    --color-home-category-news-title-bg: linear-gradient( 90deg, #f70f21 0%, #ffd9dc 100%);
    --color-details-news-tag-bg: #e6e6e6;
    --color-details-news-tag-text: #1879d0;
    --color-home-category-news-bg: linear-gradient( 317deg, transparent 30px, #f70f21 31px);
    --color-top-h-border: #0000001a;
    --color-not-found-404-btn: #e5efea;
    --color-footer-fst: linear-gradient(180deg, #f70f21 0%, #290003 100%);
    --color-footer-3rd: linear-gradient(180deg, #006837 0%, #290003 100%);
    --color-footer-full-menu: linear-gradient(to bottom, white, rgba(0, 0, 0, 0)) 1 100%;
    --coolor-footer-dev-text: #cb335b;
    --color-header-top-bar-icon: rgba(17, 17, 17, 0.3);
    --color-top-menu-modal-body: #f2f2f2;
    --color-figcaption: #ffe1e1;
    --color-tv-style-shadow: #999;
    --color-calender-selected: red;
    --color-home-category-news-edu-title-bg: linear-gradient( 90deg, #006837 0%, #31ec94 100%);
    --color-home-category-news-ex-title-bg: linear-gradient( 90deg, #f70f21 0%, #ffd9dc 100%);
    --color-home-category-news-title-bg-green: linear-gradient( 90deg, #006837 0%, #31ec94 100%);
    --color-home-category-news-title-bg-black: linear-gradient( 90deg, #000000 0%, #f0f0f0 100%);
} */

:root {
    --color-base: linear-gradient(180deg, #f70f21 0%, #290003 100%);
    --color-black: black;
    --color-white: white;
    --color-red: red;
    --color-yellow: #f3bf05;
    --color-link: #006837;
    --color-hover: #006837;
    --color-link-aria: red;
    --color-border: #e5e5e5;
    --color-border-light: #e7e7e7;
    --color-h-search-send: #cd2431;
    --color-menu-bg: linear-gradient(90deg, #f70f21 0%, #290003 100%);
    --color-header-menu-bg: linear-gradient(180deg, #f70f21 0%, #290003 100%);
    --color-header-menu-hover: #cd2431;
    --color-header-menu-active: #ec2028;
    --color-hero-news-body-border: #e5e5e5;
    --color-update-news-title-bg: #f1e8f6;
    --color-update-news-title-text: #f70f21;
    --color-home-category-news-title-bg: linear-gradient(
        179.98deg,
        #f2979e -12.48%,
        #f70f21 99.98%
    );
    --color-details-news-tag-bg: #e6e6e6;
    --color-details-news-tag-text: #1879d0;
    --color-home-category-news-bg: linear-gradient(317deg, transparent 30px, #f70f21 31px);
    --color-top-h-border: #0000001a;
    --color-not-found-404-btn: #e5efea;
    --color-footer-fst: linear-gradient(180deg, #f70f21 0%, #290003 100%);
    --color-footer-3rd: linear-gradient(180deg, #006837 0%, #290003 100%);
    --color-footer-full-menu: linear-gradient(to bottom, white, rgba(0, 0, 0, 0)) 1 100%;
    --coolor-footer-dev-text: #cb335b;
    --color-header-top-bar-icon: rgba(17, 17, 17, 0.3);
    --color-top-menu-modal-body: #f2f2f2;
    --color-figcaption: #ffe1e1;
    --color-tv-style-shadow: #999;
    --color-calender-selected: red;
    --color-home-category-news-edu-title-bg: linear-gradient(360deg, #006837 0%, #31ec94 100%);
    --color-home-category-news-ex-title-bg: linear-gradient(90deg, #f70f21 0%, #ffd9dc 100%);
    --color-home-category-news-title-bg-green: linear-gradient(360deg, #006837 0%, #31ec94 100%);
    --color-home-category-news-title-bg-black: linear-gradient(90deg, #000000 0%, #f0f0f0 100%);
}

body {
    padding: 0 !important;
}

/*Header Section*/

a {
    color: var(--color-black);
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

img {
    max-width: 100%;
}

/* .main-body {
    /* width: 840px; * /
} */

.link-area .link-title {
    color: var(--color-black);
    font-family: 'SolaimanLipi', sans-serif;
}

.link-area:hover .link-title {
    color: var(--color-hover);
}

.link-area:hover .link-title span {
    color: var(--color-hover);
}
.link-area-ex .link-title-ex {
    color: var(--color-black);
}

.link-area-ex:hover .link-title-ex {
    color: var(--color-hover);
}

.header-top {
    height: 140px;
}

.header-top-bar {
    margin-top: 10px;
    width: 100%;
    display: inline-grid;
    grid-template-columns: 50% 50%;
}

.header-top-bar-bottom {
    width: 100%;
    display: inline-grid;
    grid-template-columns: 50% 50%;
}

.header-top-bar-bottom-social {
    float: right;
    /* margin-right: -40px; */
}

.header-top-bar-bottom-social > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    float: right;
}

.header-top-bar-bottom-social > ul > li {
    float: left;
    padding-left: 12px;
}

.header-top-bar-bottom-social > ul > li > a {
    display: block;
    text-align: center;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-white);
}

/* .facebook-icon-btn {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #e8f1fe;
}

.facebook-icon-btn>img {
    margin-top: 25%;
}*/

/* .header-search-bar {
    /* margin-top: 8px; * /
} */

.header-user {
    height: 65px;
    position: relative;
}

.header-user-img {
    height: 30px;
    width: 30px;
    float: right;
    border-radius: 50%;
}

.header-usern-notification {
    height: 20px;
    width: 20px;
    margin-right: 13px;
    float: right;
    margin-top: 6px;
}

.header-top-bar-bottom > p {
    margin-top: 10px;
    margin-left: 10px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: var(--color-black);
}

.header-search-input {
    width: 370px;
    height: 40px;
    margin-top: 5px;
    margin-left: 10px;
    background: var(--color-white);
}
.header-search-input2 {
    height: 40px;
    margin-top: 16px;
    margin-left: 10px;
    background: var(--color-white);
}
.header-search-group {
    width: 346px;
    height: 40px;
    display: flex;
    flex-direction: row;
    border: 1px solid var(--color-border-light);
    box-sizing: border-box;
    border-radius: 6px;
}

.header-search-text-input {
    width: 300px;
    margin: 0;
}

.header-search-text-input > input {
    width: 100%;
    height: 100%;
}

.header-search-send {
    width: 32px;
    height: 30px;
    background: var(--color-h-search-send);
    border-radius: 5px;
    margin: auto;
    font-size: 20px;
    border: none;
}

.header-search-send > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    height: 97%;
    width: 100%;
}

.header-search-text-input > input {
    border: none;
    margin-left: 10px;
    background-color: transparent;
    font-family: 'SolaimanLipi', sans-serif;
}

.header-search-text-input > input:focus {
    outline: none;
}

.header-top-logo {
    width: 435px;
    margin-top: 10px;
}

.header-top-logo > img {
    margin-top: 6px;
    width: 260px;
    height: 99px;
}
.header-desktop-cross {
    display: none;
}
.menu-section {
    width: 100%;
    height: 50px;
    background-color: var(--color-menu-bg);
}
.mobile-sort-menu {
    display: none;
}
.sidebar-custom-width > .modal-content {
    box-shadow: 0 3px 12px 0 rgb(0 0 0 / 40%);
}
.share-text-modal {
    margin-left: 5px;
    font-size: 12px;
}
.recent-share-tr > td > button {
    display: flex;
    align-items: center;
}
/* .sidebar-desktop-modal {
  width: 300px !important;
} */

/* Position Style */

.position-lower .top-header {
    height: 50px;
}

.top-header {
    height: 50px;
    border-bottom: 1px solid var(--color-top-h-border);
    overflow: hidden;
    transition: all 0.5s ease;
}

.header-menu {
    width: 100%;
    height: 45px;
    background: var(--color-header-menu-bg);
}

.header-menu.position-higher {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;
}

.fake-height.position-higher {
    height: 45px;
}

.menu-view {
    height: 45px;
    /* background: var(--color-menu-bg); */
    padding: 0 3px;
    display: flex;
    justify-content: center;
}

.menu-view > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.menu-view > ul > li {
    display: inline-block;
    margin-right: 4mobile-menu apx;
}
.menu-view > ul > li:last-child {
    margin-right: 0;
}

.menu-view > ul > li:hover {
    background-color: var(--color-header-menu-hover);
    height: 45px;
}

.menu-view-active {
    border-bottom: 3px solid var(--color-header-menu-active);
    height: 45px;
}

.menu-view > ul > li > a {
    display: inline-block;
    padding: 14px;
    text-decoration: none;
    text-align: center;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: var(--color-white);
    position: relative;
    top: -1px;
}

.menu-view > ul > li > button {
    display: block;
    padding: 14px;
    text-decoration: none;
    text-align: center;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: var(--color-white);
    cursor: pointer;
}

.menu-view > ul > li > a:hover {
    text-decoration: none;
}

.more-menu-hide {
    display: none;
}

.more-menu-show {
    display: block;
    color: white;
    position: relative;
    z-index: 1;
    margin-left: 995px;
    background-color: var(color-base);
    width: 175px;
}

.more-menu-show > ul {
    list-style-type: none;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.more-menu-show > ul > li {
    padding-left: 10px;
}

.more-menu-show > ul > li > a {
    display: block;
    padding: 10px;
    text-decoration: none;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-white);
}

.more-menu-show > ul > li:hover {
    background-color: var(--color-header-menu-hover);
    padding-left: 10px;
}

.header-news {
    grid-template-columns: auto 25.62%;
}

.hero-news {
    /* height: 438px; */
    margin-bottom: 24px;
}

.hero-news-body {
    display: block;
    border: 1px solid var(--color-hero-news-body-border);
    height: 500px;
    position: relative;
}

.hero-news-body .image-area {
    height: 295px;
    width: 100%;
}

/* .hero-news-body-text {
} */

.hero-news-body-title {
    margin: 20px 20px 10px 20px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 38px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.hero-news-body-title span {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    color: red;
}
.hero-news-body-title span:hover {
    color: var(--color-hover);
}

.hero-news-body-detail {
    margin: 10px 20px 20px 20px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 27px;
    color: var(--color-black);
    opacity: 0.7;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.updated-news-play-side {
    height: 495px;
    border: 1px solid var(--color-hero-news-body-border);
}

/* .updated-news-2 {
    /* height: 450px; * /
}

.updated-news-op {
    /* height: 740px; * /
} */

.updated-news-op .update-news-body {
    height: 150px;
}

.updated-news {
    border: 1px solid var(--color-hero-news-body-border);
    padding-top: 10px;
    margin-bottom: 24px;
}

.update-news-all-button {
    background: var(--color-footer-3rd);
}

.updated-news-style-2 {
    border: 1px solid var(--color-hero-news-body-border);
    padding-top: 9px;
    margin-bottom: 24px;
    overflow: hidden;
    height: 500px;
}

/* .updated-news-style-2:hover {
    /* overflow-y: scroll; * /
} */

.update-news-title {
    height: 40px;
    display: flex;
    width: 100%;
    margin-bottom: 6px;
}

.update-news-title > button {
    border: none;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: var(--color-update-news-title-text);
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
    width: 100%;
    background-color: transparent;
    /* margin-left: -1px;
    margin-right: -1px; */
}

.update-news-title > button > a {
    border: none;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: var(--color-white);
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
    width: 100%;
    margin: 5px;
}

.update-news-title > button.active {
    background: var(--color-footer-3rd);
    /* background: var(--color-base); */
    color: var(--color-white);
    font-size: 16px;
}

.update-news-title > button:hover {
    background: var(--color-footer-3rd);
    color: var(--color-white);
    font-size: 16px;
}

.update-news-body {
    height: 62px;
    margin: 0 10px 10px 10px;
    display: block;
}

.updated-news .update-news-body {
    margin: 0 10px 16px 10px;
    overflow: hidden;
}

.update-news-body-scroll {
    overflow: hidden;
    height: 452px;
}

/* .update-news-body-scroll:hover {
    /* overflow-y: scroll; * /
} */

.update-news-body-img {
    height: auto;
    width: 109%;
    max-height: 50px;
    margin: 9px 0;
    position: relative;
}

.update-news-body-img > img {
    /* height: 58px; */
    width: 100%;
    object-fit: cover;
    object-position: top center;
    box-shadow: 0 0 14px 3px #99999921;
}

.update-news-body-title {
    padding-left: 5px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: var(--color-black);
    margin-top: auto;
    margin-bottom: auto;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.update-news-body-title > h5 {
    padding-left: 15px;
    font-family: 'SolaimanLipi', sans-serif;
    font-size: 17px;
    line-height: 20px;
    color: var(--color-black);
}

/* ###################### */

.article-item-opinion {
    font-family: 'SolaimanLipi', sans-serif;
    display: block;
    margin: 10px;
    padding-right: 10px;
}

.article-item-opinion .image-area {
    width: 64px;
    height: 64px;
    border-radius: 100px;
    overflow: hidden;
}

/* .article-item-opinion .image-area > img {
} */

.article-item-opinion .link-title {
    font-weight: bold;
    color: var(--color-black);
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 22px;
    -webkit-line-clamp: 3;
    padding-left: 3px;
}

.article-item-opinion .summery {
    color: var(--color-black);
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-bottom: 10px;
    font-size: 17px;
    line-height: 21px;
    -webkit-line-clamp: 2;
    opacity: 0.7;
    padding-left: 3px;
}

.article-item-opinion .author-img {
    width: 24px;
    margin-right: 10px;
}

.article-item-opinion .author {
    font-size: 17px;
    line-height: 20px;
    color: var(--color-black);
    font-weight: 400;
}

.article-item-opinion:hover .author {
    color: var(--color-hover);
}

.hero-news-padding-left-30 {
    padding-left: 30px;
}

.share-text {
    margin-left: 5px;
}

.hero-news-short {
    height: 260px;
    margin-bottom: 24px;
    border: 1px solid var(--color-hero-news-body-border);
    display: block;
}

.hero-news-short-img {
    height: 185px;
}

.hero-news-short-img > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top center;
}

.hero-news-short-img-detail {
    height: 162px;
}

.hero-news-short-img-detail > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top center;
}
.related-news-box-img {
    height: 113px;
}

.related-news-box-img > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top center;
}
.cat-news-short-img-detail {
    height: 155px;
}

.cat-news-short-img-detail > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top center;
}

.ad-one {
    margin-bottom: 24px;
    text-align: center;
}

.ad-two {
    text-align: center;
    margin-bottom: 24px;
}

.ad-one > img {
    width: 100%;
    height: 90px;
}

.ad-two > img {
    width: 70%;
    height: 90px;
}

.ad-two-full {
    text-align: center;
    margin-bottom: 24px;
}

.ad-two-full > img {
    width: 100%;
}

.hero-news-short-title {
    height: 43px;
    margin: 16px 10px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: var(--color-black);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.hero-news-short-title span {
    color: var(--color-red);
}

.home-category-news-title {
    margin-bottom: 10px;
    height: 40px;
    background: var(--color-home-category-news-title-bg);
}
.home-category-news-title2 {
    height: auto !important;
    padding-bottom: 15px;
}
.home-category-news-title-green {
    margin-bottom: 10px;
    height: 40px;
    background: var(--color-home-category-news-title-bg-green);
}

.home-category-news-title-black {
    margin-bottom: 10px;
    height: 40px;
    background: var(--color-home-category-news-title-bg-black);
}

.home-category-news-title-sub {
    width: 148px;
    height: 40px;
    position: relative;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: var(--color-white);
    display: block;
}

.home-category-news-title-sub > a {
    position: relative;
    left: 20px;
    top: 12px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: var(--color-white);
}

.home-category-news-title > h5 {
    position: relative;
    left: 20px;
    top: 12px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
}

/* .hero-news-short-footer {
} */

.hero-news-short-footer > a {
    float: right;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    padding-right: 10px;
}

.sidebar-calendar {
    margin-bottom: 24px;
    border: 1px solid var(--color-hero-news-body-border);
    padding: 10px;
}

.sidebar-calendar-weekdays > td {
    width: 14.28%;
}

.sidebar-calendar-month {
    padding: 10px;
    width: 279px;
    height: 40px;
    background: var(--color-base);
    text-align: center;
}

.sidebar-calendar-month > ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidebar-calendar-month-text {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-white);
}

.sidebar-calendar-month-text-arch {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: var(--color-white);
    margin-left: -76px;
}

.sidebar-calendar-month .prev {
    float: left;
}

.sidebar-calendar-month .next {
    float: right;
}

.sidebar-calendar-weekdays {
    margin: 0;
    padding: 10px 0;
}

.sidebar-calendar-weekdays-text {
    display: inline-block;
    text-align: center;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    margin-top: 5px;
    color: var(--color-black);
}

.sidebar-calendar-days {
    padding: 10px 0;
    margin: 0;
}

.sidebar-calendar-days-text {
    list-style-type: none;
    display: inline-block;
    text-align: center;
    margin-bottom: 5px;
    font-size: 12px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 17px;
    color: var(color-base);
}

.update-news-top-bottom-margin-auto {
    display: flex;
}

.holiday > a {
    color: var(--color-red);
}

.today {
    width: 26px;
    height: 21px;
    background-color: var(--color-calender-selected);
    border-radius: 5px;
}

.today > a {
    color: var(--color-white);
}

.special-feature {
    border-left: 1px solid #cfcfcf;
    border-bottom: 1px solid #cfcfcf;
    border-right: 1px solid #cfcfcf;
    margin-bottom: 24px;
}

.special-feature-padding {
    padding: 12px;
}

.special-feature-footer {
    height: 42px;
    border-top: 1px solid #cfcfcf;
    text-align: right;
    padding-top: 15px;
}

.special-news-box {
    height: 304px;
}

.special-feature-footer > a {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: #000000;
}

.special-news-title {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: #000000;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 12px;
    margin-top: 12px;
    height: 40px;
    padding-left: 2px;
}

.special-news-title span {
    color: var(--color-red);
}

.special-news-detail {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 12px;
    padding-left: 2px;
}

.sidebar-calendar-active {
    padding: 5px;
    background: var(color-base);
    border-radius: 5px;
    width: 26px;
    height: 21px;
    color: var(--color-white) !important;
}

.sidebar-news {
    padding-right: 0px;
}

.sidebar-add-one {
    margin-bottom: 24px;
    background: url(../../assets/images/ads-placeholder-300.svg) no-repeat 50%;
    background-size: contain;
    text-align: center;
    background-color: #efefef;
    height: 250px;
}

.ads-19 {
    height: 350px;
}

.sidebar-add-one > img {
    width: 100%;
    height: 100%;
}

.sidebar-add-20 {
    margin-bottom: 24px;
    background: url(../../assets/images/ads-placeholder-300.svg) no-repeat 50%;
    background-size: contain;
    text-align: center;
    background-color: #efefef;
    height: auto;
}

.sidebar-add-20 > img {
    width: 100%;
    height: 100%;
}

.sidebar-add-twelve {
    margin-bottom: 24px;
    background: url(../../assets/images/ads-placeholder-300.svg) no-repeat 50%;
    background-size: contain;
    text-align: center;
    background-color: #efefef;
    height: 250px;
}

.sidebar-add-twelve > img {
    width: 100%;
    height: 100%;
}

.sidebar-category-news {
    border: 1px solid var(--color-hero-news-body-border);
    margin-bottom: 24px;
    border-top: none;
    /* border-radius: 5px;
    overflow: hidden; */
}

.sidebar-category-news-title {
    margin-bottom: 10px;
    height: 40px;
    background: var(--color-home-category-news-title-bg);
    margin-left: -1px;
    margin-right: -1px;
}

.sidebar-survey-news {
    border: 1px solid var(--color-hero-news-body-border);
    margin-bottom: 24px;
    border-top: none;
}

.sidebar-survey-news-title {
    height: 40px;
    background: var(--color-home-category-news-title-bg);
    margin-left: -1px;
    margin-right: -1px;
}

.sidebar-survey-news-title-sub {
    width: 148px;
    height: 40px;
    position: relative;
    background: var(color-base);
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: var(--color-white);
}

.sidebar-survey-news-title-sub > a {
    position: relative;
    left: 20px;
    top: 12px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: var(--color-white);
}

.sidebar-survey-news-body {
    background: #dfecdd;
    padding: 7px;
    overflow: hidden;
}

.sidebar-survey-news-body-q {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
}

.sidebar-survey-news-body-a {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    padding: 10px;
}

.sidebar-survey-news-body-a > button {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    padding: 10px;
    background: var(--color-base);
    color: var(--color-white);
    border: none;
    border-radius: 5px;
}

.sidebar-category-news-title-green {
    margin-bottom: 10px;
    height: 40px;
    background: var(--color-home-category-news-title-bg-green);
}

.sidebar-category-news-title-black {
    margin-bottom: 20px;
    height: 40px;
    background: var(--color-home-category-news-title-bg-black);
    margin-left: -1px;
    margin-right: -1px;
}

.sidebar-category-news-title-sub {
    width: 148px;
    height: 40px;
    position: relative;
    background: var(color-base);
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: var(--color-white);
}

.sidebar-category-news-title-ex {
    margin-bottom: 10px;
    height: 40px;
    /* background: var(--color-home-category-news-ex-title-bg); */
    background: var(--color-header-menu-bg);
    margin-left: -1px;
    margin-right: -1px;
}

.sidebar-category-news-title-ex-sub {
    width: 148px;
    height: 40px;
    position: relative;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: var(--color-white);
}

.sidebar-category-news-title-edu {
    height: 40px;
    background: var(--color-home-category-news-edu-title-bg);
    margin-left: -1px;
    margin-right: -1px;
}

.sidebar-category-news-title-edu-sub {
    width: 148px;
    height: 40px;
    position: relative;
    background: var(color-base);
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: var(--color-white);
}

.sidebar-category-news-title-sub > a {
    position: relative;
    left: 20px;
    top: 12px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: var(--color-white);
}

.sidebar-category-news-title-ex-sub > a {
    position: relative;
    left: 20px;
    top: 12px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: var(--color-white);
}

.sidebar-category-news-title-edu-sub > a {
    position: relative;
    left: 20px;
    top: 12px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: var(--color-white);
}

.app-download-section {
    margin-top: 30px;
    width: 300px;
    height: 165px;
    background: linear-gradient(106.83deg, #59d5b7 0%, #32a350 100%);
}

.app-download-section-text {
    padding-top: 21px;
    margin-left: 17px;
    margin-right: 18px;
    margin-bottom: 20px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: var(--color-white);
}

.app-download-section-btn {
    padding-top: 12px;
    padding-left: 20px;
    margin: auto;
    width: 175px;
    height: 50px;
    background: var(--color-yellow);
    border-radius: 12px;
}

.app-download-section-btn > button {
    border: none;
    background-color: transparent;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: var(--color-white);
}

.side-news-short {
    width: 100%;
    padding: 10px;
    padding-top: 0px;
    display: block;
}

.side-news-short-img {
    max-height: 80%;
}

.side-news-short-img > img {
    width: 100%;
    object-fit: cover;
    object-position: top center;
}

.side-news-short-title {
    height: 52px;
    margin-top: 20px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: var(--color-black);
}

.category-news-footer {
    width: 100%;
    height: 50px;
    border-top: 1px solid var(--color-border-light);
}

.category-news-footer-text {
    width: 75px;
    height: 30px;
    float: right;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 30px;
    color: var(--color-black);
    padding-right: 10px;
}

.category-news-footer-text:hover {
    color: var(color-base);
}

.entertainment-body {
    margin-top: 0px;
}

.carousel-item > img {
    height: 500px !important;
}

.carousel-item > img {
    width: 100%;
    height: 400px !important;
    object-fit: cover;
    object-position: top center;
}

.carousel-item-srt {
    width: 150px;
    margin-bottom: 24px;
}

.bottom-category-news {
    overflow: hidden;
    border: 1px solid var(--color-border);
    margin-bottom: 24px;
}

.bottom-category-news-title {
    margin-bottom: 10px;
    height: 40px;
    background: var(--color-home-category-news-title-bg-green);
    margin-left: -1px;
    margin-right: -1px;
}

.bottom-category-news-title-sub {
    width: 148px;
    height: 40px;
    position: relative;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: var(--color-white);
}

.bottom-category-news-title-sub > a {
    position: relative;
    left: 20px;
    top: 12px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: var(--color-white);
}

.bottom-news-short {
    height: 212px;
}

.bottom-news-short-img {
    height: 157px;
    margin: 10px;
}

.bottom-news-short-img > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top center;
}

.bottom-news-short-title {
    height: 52px;
    margin: 10px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: var(--color-black);
    padding: 10px;
}

.bottom-news-short-title > h4 {
    height: 52px;
    margin-top: 10px;
    margin-left: 5px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: var(--color-black);
}

/* .bottom-news-body {
    /* height: 90px; * /
} */

.bottom-news2 {
    height: 60px;
    margin-bottom: 12px;
}

.bottom-news-body-img {
    height: 60px;
    /* margin-left: 10px; */
}

.bottom-news-body-img > img {
    height: 60px;
    width: 112%;
}

.bottom-news-body-title {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: var(--color-black);
    margin-top: auto;
    margin-bottom: auto;
}

.bottom-news-body-title > h5 {
    padding-left: 15px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: var(--color-black);
}

.bottom-news-short-divider {
    margin-top: 0px;
    margin-bottom: 10px;
    width: 75vw;
}

.bottom-news-footer {
    height: 50px;
    border-top: 1px solid var(--color-border-light);
}

.bottom-news-footer-text {
    width: 75px;
    height: 30px;
    float: right;
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 30px;
    color: var(--color-black);
    padding-right: 10px;
}

.hero-news-short-play-top-title {
    margin-bottom: 10px;
    height: 40px;
    background: var(--color-home-category-news-title-bg);
}

.hero-news-short-play-top-title-sub {
    width: 148px;
    height: 40px;
    position: relative;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: var(--color-white);
}

.hero-news-short-play-top-title-sub > a {
    position: relative;
    left: 20px;
    top: 12px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: var(--color-white);
}

.hero-news-short-play {
    margin-bottom: 17px;
    border: 1px solid var(--color-border);
}

/* .hero-news-short-play-row {
} */

.hero-news-short-play-img {
    height: 150px;
}

.hero-news-short-play-img > img {
    height: 150px;
    width: 100%;
    object-fit: cover;
    object-position: top center;
}

.hero-news-short-play-title {
    margin: 10px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: var(--color-black);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.hero-news-short-play-detail {
    margin: 10px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    height: 40px;
    color: var(--color-black);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.category-hero-news-body {
    width: 550px;
    border: 1px solid var(--color-border);
}

.category-hero-news-body > img {
    height: 295px;
    width: 548px;
}

.category-hero-news-short {
    margin-bottom: 24px;
    border: 1px solid var(--color-border);
    display: block;
}

.category-news {
    overflow: hidden;
    display: flex;
    margin-bottom: 24px;
    padding: 10px;
    border: 1px solid var(--color-border-light);
    box-sizing: border-box;
}

.category-news-img {
    flex: 0 0 300px;
    width: 300px;
}

.category-news-img > img {
    height: auto;
    width: 100%;
    object-fit: cover;
    object-position: top center;
}

.category-news-title {
    flex: 1 1 auto;
    margin-left: 20px;
}

.category-news-title > h4 {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 28px;
    padding: 10px 0;
    color: var(--color-black);
    overflow: hidden;
}
.category-news-title > h4 > span {
    color: var(--color-red);
}

.category-news-title > h5 {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    color: var(--color-black);
    opacity: 0.7;
}

.category-news-f-time {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    color: var(--color-black);
    opacity: 0.7;
}

.category-news-more-btn {
    width: 142px;
    height: 50px;
    left: 724px;
    top: 2137px;
    border: 1px solid var(--color-red);
    box-sizing: border-box;
    margin: auto;
    text-align: center;
    padding-top: 10px;
    margin-bottom: 24px;
    background: transparent;
    color: var(--color-red);
    font-family: 'SolaimanLipi', sans-serif;
    cursor: pointer;
}

.category-news-more-btn:hover {
    color: var(--color-red);
    background: transparent;
    border: 1px solid var(--color-red);
}
.category-news-more-btn:active {
    color: var(--color-red);
    background: transparent;
    border: 1px solid var(--color-red);
}
.category-news-more-btn:focus {
    color: var(--color-red);
    background: transparent;
    border: 1px solid var(--color-red);
    box-shadow: none;
}
.category-news-more-btn > button {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: var(--color-red);
    border: none;
    background: none;
}
.category-news-more-btn a {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: var(--color-red);
    border: none;
    background: none;
}

.category-news-more-btn > button:hover {
    color: var(--color-red) !important;
    background-color: none !important;
    border-color: none !important;
    background: none !important;
}

.category-news-more-btn > button:active {
    color: var(--color-red) !important;
    background-color: none !important;
    border-color: none !important;
    background: none !important;
}

.category-news-more-btn > button:focus {
    box-shadow: none !important;
    background-color: none !important;
    border-color: none !important;
    background: none !important;
    color: var(--color-red) !important;
}
.detail-page-divider {
    display: flex;
    margin-bottom: 30px;
    align-items: center;
    height: 40px;
}
.detail-page-divider > .first-divider {
    flex: 0 0 auto;
    width: 44%;
    padding-right: 5px;
    height: 15px;
}
.detail-page-divider > .second-divider {
    width: 12%;
    display: flex;
    justify-content: center;
    justify-content: center;
    padding-top: 12px;
}
.detail-page-divider > .third-divider {
    flex: 0 0 auto;
    width: 44%;
    padding-right: 5px;
    height: 15px;
}
/* .detail-read-more {
} */
.detail-read-more-title {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    color: #000000b8;
    margin-bottom: 10px;
}
.detail-read-more-news {
    display: flex;
    border: 1px solid #e2e2e2;
    padding: 10px 10px 10px 20px;
}
.detail-read-more-news h4 {
    width: 85%;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 45px;
    color: var(--color-black);
}
.detail-read-more-news img {
    width: 15%;
}
.footer {
    margin-top: 100px;
    height: 322px;
}

.footer-logo {
    margin-top: 30px;
    width: 111px;
    height: 43px;
}

.footer-logo > img {
    width: 111px;
    height: 43px;
}

.footer-editor {
    margin-top: 18px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: var(--color-black);
}

.footer-address {
    margin-top: 13px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: var(--color-black);
    opacity: 0.7;
}

.footer-add-close {
    border: none;
    background: none;
    font-size: 18px;
    color: red;
    position: fixed;
    top: -15px;
    background: #f2f2f2;
    border-radius: 49%;
    width: 30px;
    height: 30px;
    border: 1px solid red;
}

.footer-add-modal-content {
    background: #f2f2f2 !important;
    margin-bottom: 35px;
    box-shadow: 0px -10px 50px rgba(247, 15, 33, 0.05);
    border: none;
    height: 90px;
    border: none !important;
    border-radius: unset !important;
}

.footer-add-img {
    height: 88px;
}

.footer-bottom {
    display: inline-grid;
    width: 1170px;
    grid-template-columns: 70% 30%;
}

.footer-bottom > h5 {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: var(--color-black);
    opacity: 0.7;
}

.footer-menu > ul {
    list-style: none;
    margin-top: 46px;
}

.footer-menu > ul > li > a {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 36px;
    color: var(--color-black);
    opacity: 0.7;
}

.search-icon {
    border: 0;
    background: none;
    height: 40px;
    width: 40px;
    border-radius: 100%;
}

.search-icon:hover {
    background-color: #ff000040;
}

.search-icon > img {
    cursor: pointer;
    cursor: pointer;
    position: relative;
    left: 2px;
    top: 2px;
}
.search-icon2 {
    border: 0;
    background: none;
    height: 37px;
    width: 60px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
}

.details-title {
    width: 100%;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 45px;
    color: var(--color-black);
    margin-top: 18px;
}

.details-pre-title {
    width: 100%;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 45px;
    color: var(--color-red);
    margin-top: 18px;
    margin-bottom: -25px;
}

.details-sub-title {
    width: 100%;
    font-family: 'SolaimanLipi', sans-serif;
    font-weight: normal;
    font-size: 18px;
    color: #333;
    line-height: 150%;
    text-align: justify;
}

.details-reporter-info {
    margin-top: 20px;
    margin-bottom: 24px;
}

.details-reporter-img {
    width: 50px;
    height: 58.31px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.details-reporter-img img {
    border-radius: 50%;
}

.details-reporter-type {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-black);
    margin-top: 5px;
}

.details-reporter-type > img {
    margin-right: 10px;
}

.details-report-time {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: var(--color-black);
    opacity: 0.5;
    margin-bottom: -5px;
}

.details-reporter-divider {
    margin-top: 3px;
    margin-bottom: 3px;
}

.details-sidebar-share {
    position: absolute;
    z-index: 10;
}

.details-sidebar-share > ul {
    list-style: none;
    position: relative;
    left: -84px;
    top: -45px;
}

.details-sidebar-share > ul > li {
    margin: 5px;
}

.details-social {
    margin-top: 7px;
    font-family: 'SolaimanLipi', sans-serif;
    position: relative;
}

.details-social > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.details-social > ul > li {
    display: inline-block;
    padding-right: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
}

.details-social > ul > li > a {
    display: block;
    text-align: center;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-white);
}

.details-share-text {
    margin-top: 5px;
}

/* .details-news-body {
    /* width: 840px; * /
} */

.details-news-body > img {
    height: 486px;
    /* width: 840px; */
}

.details-news-body-detail {
    /* margin: 14px; */
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    color: var(--color-black);
    opacity: 0.9;
}

.details-news-body-detail p {
    font-size: 20px;
    text-align: justify;
}

.details-news-body-detail blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 0.7em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
}

.details-news-body-detail blockquote p:before {
    content: url('../images/IconQuoteStart.svg');
    position: relative; /*or absolute*/
    z-index: 100000; /*a number that's more than the modal box*/
    left: -11px;
    top: 0px;
}

.details-news-body-detail blockquote p:after {
    content: url('../images/IconQuoteEnd.svg');
    position: absolute;
    z-index: 100000;
}
.table {
    width: 100% !important;
}
/* 
blockquote p:before {
    content: '"';
    font-size: 30px;
    }
    blockquote p:after {
    content: '"';
    font-size: 30px;
    } */
.share-round-cons .jssocials-share-link {
    font-size: 12px;
}

.details-share-fb {
    width: 33px !important;
    height: 33px !important;
    /* background-color: #e7e7e7 !important; */
    border-radius: 50px;
    /* background-image: url('../images/fbDetailColor.svg'); */
    background-repeat: no-repeat;
    background-position: center;
    background-color: #3b5998 !important;
    background-image: url('../images/fbDetail.svg');
    background-size: 23%;
}

/* .details-share-fb:hover {
    background-color: #1877f2 !important;
    background-image: url('../images/fbDetail.svg');
} */

.details-share-tw {
    width: 33px !important;
    height: 33px !important;
    /* background-color: #e7e7e7 !important; */
    border-radius: 50px;
    /* background-image: url('../images/twDetail.svg'); */
    background-repeat: no-repeat;
    background-position: center;
    background-color: #00aced !important;
    background-image: url('../images/twDetailW.svg');
    background-position: 57%;
    background-size: 52%;
}

/* .details-share-tw:hover {
    background-color: #1d9bf0 !important;
    background-image: url('../images/twDetailW.svg');
} */

.details-share-wh {
    width: 33px !important;
    height: 33px !important;
    /* background-color: #e7e7e7 !important; */
    border-radius: 50px;
    /* background-image: url('../images/whDetail.svg'); */
    background-repeat: no-repeat;
    background-position: center;
    background-color: #25d366 !important;
    background-image: url('../images/whDetailC.svg');
    background-size: 62%;
    background-position: 59%;
}
/* 
.details-share-wh:hover {
    background-color: #0dc143 !important;
    background-image: url('../images/whDetailC.svg');
} */
.details-share-email {
    width: 33px !important;
    height: 33px !important;
    /* background-color: #e7e7e7 !important; */
    border-radius: 50px;
    /* background-image: url('../images/emailIcon-color.svg'); */
    background-repeat: no-repeat;
    background-position: center;
    background-color: #111111 !important;
    background-image: url('../images/emailIcon-white.svg');
}
/* 
.details-share-email:hover {
    background-color: #111111 !important;
    background-image: url('../images/emailIcon-white.svg');
} */

.details-share-more {
    width: 33px !important;
    height: 33px !important;
    /* background-color: #e7e7e7 !important; */
    border-radius: 50px;
    /* background-image: url('../images/moreIcon-color.svg'); */
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    position: relative;
    top: -11.5px;
    background-color: #006736 !important;
    background-image: url('../images/share-icon.svg');
    /* background-size: 70%;
    background-position: 64%; */
}

/* .details-share-more:hover {
    background-color: #006736 !important;
    background-image: url('../images/share-icon.svg');
} */

.details-share-save {
    width: 33px !important;
    height: 33px !important;
    /* background-color: #e7e7e7 !important; */
    border-radius: 50px;
    /* background-image: url('../images/saveIcon-color.svg'); */
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    position: relative;
    top: -11.5px;
    background-color: #f70f21 !important;
    background-image: url('../images/not-save-icon.svg');
    /* background-size: 50%; */
}
/* 
.details-share-save:hover {
    background-color: #f70f21 !important;
    background-image: url('../images/not-save-icon.svg');
} */

.details-share-save-active {
    width: 33px !important;
    height: 33px !important;
    border-radius: 50px;
    background-color: #f70f21 !important;
    background-image: url('../images/not-save-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    position: relative;
    top: -11.5px;
}

.details-share-printer {
    width: 33px !important;
    height: 33px !important;
    /* background-color: #e7e7e7 !important; */
    border-radius: 50px;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    /* background-image: url('../images/printerIcon-color.svg'); */
    position: relative;
    top: 3px;
    background-color: #111111 !important;
    background-image: url('../images/print-solid-white.svg');
    /* background-size: 65%; */
}

.details-share-body-copy {
    width: 33px !important;
    height: 33px !important;
    /* background-color: #e7e7e7 !important; */
    border-radius: 50px;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    position: relative;
    top: -11px;
    background-color: #00aced !important;
    background-image: url('../images/IconCopy.svg');
    background-size: 43%;
}
/* 
.details-share-printer:hover {
    background-color: #111111 !important;
    background-image: url('../images/print-solid-white.svg');
} */

.fb-share-hover:hover {
    color: #1877f2;
}

.me-share-hover:hover {
    color: #2196f3;
}

.pi-share-hover:hover {
    color: #cb2128;
}

.wh-share-hover:hover {
    color: #25d366;
}

.tw-share-hover:hover {
    color: #00aced;
}

.li-share-hover:hover {
    color: #007fb1;
}

.em-share-hover:hover {
    color: #7f7f7f;
}

.co-share-hover:hover {
    color: #0d6efd;
}

figure.image {
    text-align: center;
    /* background: var(--color-home-category-news-title-bg); */
    /* background-color: #d9dddb; */
    width: 100%;
    margin-top: 20px;
    margin-bottom: 25px;
}

figure img {
    width: 100%;
    /* max-height: 400px; */
}

figure.table table {
    width: 100%;
}
.details-news-tag {
    margin-top: 30px;
}

.details-news-tag > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.details-news-tag > ul > li {
    display: inline-block;
    padding-left: 15px;
    margin-bottom: 15px;
}

.details-news-tag > ul > li > a {
    display: block;
    text-align: center;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    width: 100px;
    height: 40px;
    color: var(--color-details-news-tag-text);
    background: var(--color-details-news-tag-bg);
    padding-top: 12%;
}
.details-news-tag > ul > li > button {
    height: 30px;
    background-color: #e7e7e7;
    border: none;
    outline: none;
    font-family: 'SolaimanLipi';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #f70f21;
}
.fb-comment-box {
    padding: 0 8px;
    margin-bottom: 9px;
}

.details-comment-info {
    height: auto;
    font-family: 'SolaimanLipi', sans-serif;
    margin-bottom: 20px;
}

.details-comment-input {
    width: 100%;
}

.details-comment-group {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-wrap: wrap;
}

.details-comment-text-input {
    width: 100%;
    margin: auto;
    border: 1px solid var(--color-border-light);
}

.details-comment-post-btn {
    width: 100%;
    height: 38px;
    margin-top: 10px;
}

.details-comment-post-btn > button {
    width: 100px;
    background-color: transparent;
    border: none;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-black);
    text-align: center;
    margin-left: 20px;
    background: var(--color-border-light);
    float: right;
}

.details-comment-send > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    height: 97%;
    width: 50%;
}

.details-comment-text-input > textarea {
    border: none;
    margin-left: 10px;
    background-color: transparent;
    height: 100%;
    width: 100%;
    outline: none;
    resize: none;
}

.details-comment-text-input > input:focus {
    outline: none;
}

.details-commenter-name {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-black);
}

.details-commenter-name > small {
    padding-left: 12px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: var(--color-black);
    opacity: 0.6;
}

.details-commenter-delete-button {
    padding-left: 12px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: var(--color-black);
    opacity: 0.6;
    border: none;
    background: none;
}

.details-comment {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-black);
    opacity: 0.6;
}

.details-comment-option {
    margin-top: 10px;
}

.details-comment-option ul {
    display: inline-block;
    padding-left: 0px;
}

.details-comment-option ul li {
    list-style: none;
    display: inline-block;
    margin-right: 20px;
}

.details-comment-option ul li button {
    border: none;
    background: none;
}

.details-realted-news-title {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 25px;
    color: var(--color-black);
    margin-bottom: 24px;
}
.archive-title {
    font-family: 'SolaimanLipi';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.archive-back-btn {
    width: 120px;
    height: 40px;
    background: linear-gradient(360deg, #006837 0%, #31ec94 100%);
    display: flex;
    align-items: center;
    justify-content: center;
}
.archive-back-btn a {
    font-family: 'SolaimanLipi';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #ffffff;
}
/* Bishojit */

.col-840 {
    flex: 72.5%;
    max-width: 72.5%;
}

.col-300-1 {
    flex: 27.5%;
    max-width: 27.5%;
}

.col-510 {
    flex: 62.06%;
    max-width: 62.06%;
}

.col-300 {
    flex: 37.94%;
    max-width: 37.94%;
}

.hero-news-detail {
    height: auto;
}

.display-show {
    display: block;
}

.display-hide {
    display: none;
}

.not-found-404 {
    margin: auto;
    text-align: center;
    border: 1px solid #e5e5e5;
    padding: 50px;
}

.not-found-404 > h2 {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;
    line-height: 58px;
    color: var(--color-black);
    margin-top: -15px;
}

.not-found-404-text {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 40px;
    color: var(--color-black);
    text-align: center;
}

.not-found-404-search {
    margin: auto;
    width: 480px;
    padding-top: 25px;
}

.not-found-404-btn {
    width: 140px;
    height: 45px;
    margin-top: 25px;
    background: var(--color-not-found-404-btn);
    border-radius: 5px;
    text-align: center;
    padding-top: 1%;
    display: inline-block;
}

.not-found-404-btn > a {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: var(color-base);
}

/*Full width footer*/

.footer-full {
    /* height: 253px; */
    margin-bottom: 35px;
}

.footer-full-fst {
    height: 123px;
    background: var(--color-footer-fst);
}

.footer-full-2nd {
    height: 45px;
}

.footer-full-3rd {
    height: 50px;
    background: var(--color-footer-3rd);
}

.footer-full-menu {
    min-height: 100px;
    margin-top: 15px;
}

.footer-full-menu > ul {
    list-style: none;
    text-align: center;
    padding-left: 0px;
}

.footer-full-menu > ul > li {
    display: inline-block;
    margin: 2px;
}

.footer-full-menu > ul > li > a {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: var(--color-white);
}

.footer-full-menu > ul > li::after {
    content: ' | ';
    color: var(--color-white);
    position: relative;
    font-size: 12px;
    top: -2px;
    opacity: 0.5;
}

.footer-full-menu > ul > li:last-child::after {
    display: none;
}

.footer-border-image {
    border-right-width: 1px;
    border-right-style: solid;
    border-image: var(--color-footer-full-menu);
}

.footer-full-menu-2 {
    min-height: 100px;
    margin-top: 15px;
    border-right-width: 1px;
    border-right-style: solid;
    border-image: var(--color-footer-full-menu);
    margin-left: -24px;
    padding-top: 21px;
}

.footer-full-menu2 > ul {
    list-style: none;
    text-align: center;
    padding-left: 0px;
}

.footer-full-menu-2 > ul > li {
    display: inline-block;
    margin: 2px;
}

.footer-full-menu-2 > ul > li > a {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: var(--color-white);
}

.footer-full-menu-2 > ul > li::after {
    content: '|';
    color: var(--color-white);
    /* margin-left: 5px; */
    font-size: 12px;
}

.footer-full-menu-2 > ul > li:last-child::after {
    display: none;
}

.footer-full-share {
    margin-top: 15px;
}

.footer-full-share > h5 {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    text-align: center;
    color: var(--color-white);
}

.footer-full-share > ul {
    list-style: none;
    padding: 0;
    text-align: center;
}

.footer-full-share > ul > li {
    display: inline-block;
    margin: 5px;
}

.footer-full-editor {
    margin-top: 3%;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    color: var(--color-black);
}

.footer-full-editor > img {
    padding-right: 10px;
}

.footer-full-app-download {
    margin-top: 3%;
    float: right;
}

.footer-full-app-download-btn {
    padding-top: 12px;
    padding-left: 20px;
    margin: auto;
    width: 175px;
    height: 50px;
    background: var(--color-black);
    border-radius: 12px;
}

.footer-full-app-download-btn > button {
    border: none;
    background-color: transparent;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    color: var(--color-white);
}

.footer-full-all-right {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: var(--color-white);
    text-align: center;
    padding-top: 1%;
}

.footer-full-develop {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: var(--color-black);
    float: right;
    margin-top: 3%;
}

.footer-full-develop > a {
    text-decoration: none;
    color: var(--coolor-footer-dev-text);
}

.footer-floating-ad {
    width: 100%;
    height: 100px;
}

.footer-floating-ad > img {
    width: 100%;
    height: 100%;
}

.header-col-452 {
    flex: 38.63%;
    max-width: 38.63%;
}

.header-col-646 {
    flex: 51.21%;
    max-width: 51.21%;
}

.header-col-72 {
    flex: 08.71%;
    max-width: 08.71%;
}

.header-logo-align {
    text-align: center;
}

.header-top-bar-icon {
    height: 80px;
    width: 100%;
    position: relative;
}

.header-top-bar-icon > p {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: var(--color-header-top-bar-icon);
    display: flex;
    align-items: center;
    height: 40px;
}

.header-top-bar-icon > p > button {
    margin-left: 10px;
}

.header-top-bar-icon > p > img {
    cursor: pointer;
}
.header-top-bar-date-time > span {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: var(--color-black);
}

.header-top-logo2 {
    display: inline-block;
}

.header-top-logo2 > img {
    margin-top: 10px;
    width: 290px;
}

.header-top-edition > p {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-align: right;
    color: var(--color-header-top-bar-icon);
}

.header-top-edition > p > b {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: var(--color-black);
}

.header-top-social {
    float: right;
}

.header-top-social-list {
    float: right;
    /* margin-right: -40px; */
}

.header-top-social-list > ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    float: right;
}

.header-top-social-list > ul > li {
    float: left;
    padding-left: 12px;
}

.header-top-social-list > ul > li > a {
    display: block;
    text-align: center;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-white);
}

.header-top-social-list > ul > li > a > svg {
    border-radius: 50%;
}

.footer-full-share > ul > li > a > svg {
    border-radius: 50%;
}
.header-top-social-list > ul > li > button > svg {
    border-radius: 50%;
}
.footer-full-share > ul > li > button > svg {
    border-radius: 50%;
}
.top-menu-modal-content {
    top: -30px;
}

.top-menu-modal-body {
    min-height: 204px;
    background-color: var(--color-top-menu-modal-body);
}

.top-m-menu-col {
    flex: 20%;
    max-width: 20%;
}

.top-m-menu-col > ul > li {
    list-style: none;
    padding: 10px;
}

.top-m-menu-col > ul > li > a {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
}

.header-search-bar2 {
    position: absolute;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-top: -16px;
    left: -14px;
}

.header-search-bar2-hide {
    display: none;
}

.header-search-bar2-show {
    display: flex;
}

.header-search-icon-show {
    display: initial;
    cursor: pointer;
}

.modal-header > .close {
    position: relative;
    top: 17px;
    color: var(--color-red);
}

.modal-header > .close > span {
    font-size: 25px;
}

figcaption {
    background: #ffffff;
    color: rgba(46, 38, 38, 0.8784313725490196);
    text-align: left;
    margin-top: 8px;
    padding-bottom: 2px;
    border-bottom: 1px solid #ddd;
    font-family: 'SolaimanLipi';
}

.floating-button {
    width: 55px;
    height: 55px;
    position: fixed;
    bottom: 46px;
    right: 0;
    z-index: 100;
    background: var(--color-base);
    box-shadow: 0px 4px 10px rgba(0, 44, 23, 0.2);
    border-radius: 30px;
    margin-right: 10px;
    z-index: 99999;
}

.floating-button-text {
    position: absolute;
    margin: auto;
    width: 37px;
    height: 34px;
    margin-top: auto;
    margin-bottom: auto;
}

.floating-button > a {
    position: absolute;
    top: 23%;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #ffffff;
}

.floating-button-day {
    width: 90px;
    height: 90px;
    position: fixed;
    bottom: 46px;
    left: 10px;
    z-index: 100;
    /* background: var(--color-base); */
    box-shadow: 0px 4px 10px rgba(0, 44, 23, 0.2);
    border-radius: 50px;
    margin-right: 10px;
    z-index: 99999;
    display: flex;
    /* display: none; */
}

.floating-button-day > img {
    width: 100%;
    border-radius: 50%;
}

.tv-style {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background: var(--color-white);
    color: var(--color-white);
    height: 35px;
    z-index: 100;
    box-shadow: 0px 0px 9px -1px var(--color-tv-style-shadow);
    display: flex;
}

.tv-style .title {
    width: 10%;
    min-width: 100px;
    background: var(--color-footer-fst);
    color: var(--color-white);
    text-align: center;
    line-height: 35px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

.tv-style .text {
    width: 90%;
    float: right;
    line-height: 35px;
    font-family: 'SolaimanLipi', sans-serif;
}

.tv-style .text marquee a {
    color: var(--color-black);
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    display: inline-block;
}

.tv-style .text marquee a:hover {
    color: var(--color-red);
}
.tv-style-breaking {
    height: 50px;
}
.tv-style-breaking > .title {
    font-size: 25px;
    padding-top: 8px;
}
.tv-style > .text > .marquee-container > .overlay {
    display: none !important;
}
.photo-details-img > img {
    margin-bottom: 10px;
    color: var(--color-black);
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
}

.photo-details-img > img {
    margin-bottom: 10px;
}

.photo-details-img > img {
    width: 100%;
}

.photo-details-img-cap {
    margin-bottom: 24px;
}

.photo-details-summary {
    /* border-bottom: 1px solid #e2e2e2;
    border-top: 1px solid #e2e2e2; */
    color: var(--color-black);
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    text-align: center;
    line-height: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.gallary-thum-img {
    height: 100px;
    width: 100px;
    object-fit: cover;
    object-position: top;
}

.p-hover a:focus {
    outline: none;
}

.p-hover a:after {
    content: '';
    font-family: 'Glyphicons Halflings';
    opacity: 0;
    /* background-color: rgba(0, 0, 0, 0.75); */
    position: absolute;
    right: 3px;
    left: 3px;
    top: 3px;
    bottom: 3px;
    text-align: center;
    line-height: 350px;
    font-size: 30px;
    color: #fff;
    transform: scale(1.1);
}

.p-hover a:hover:after {
    opacity: 1;
}

.p-hover2 a:focus {
    outline: none;
}

.p-hover2 a:after {
    content: '';
    font-family: 'Glyphicons Halflings';
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.75);
    position: absolute;
    right: 14px;
    left: 14px;
    top: 3px;
    bottom: 3px;
    text-align: center;
    line-height: 350px;
    font-size: 30px;
    color: #fff;
    -webkit-transition: all 0.5s ease-in-out 0s;
    -moz-transition: all 0.5s ease-in-out 0s;
    transition: all 0.5s ease-in-out 0s;
}

.p-hover2 a:hover:after {
    opacity: 1;
}

.inner-p-0 {
    padding-left: 0px;
}

.inner-p-7 {
    padding-right: 7px;
}

.img-wrapper {
    width: 100%;
    height: 400px;
    overflow: hidden;
    height: 276px;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
}

.inner-img {
    transition: 0.3s;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.inner-img:hover {
    transform: scale(1.1);
}

.img-wrapper2 {
    width: 100%;
    height: 400px;
    overflow: hidden;
    margin-bottom: 7px;
    height: 319px;
    position: relative;
}

.inner-img2 {
    transition: 0.3s;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.inner-img2:hover {
    transform: scale(1.1);
}

.inner-overlay {
    height: 120px;
    width: 100%;
    background: linear-gradient(180deg, rgba(19, 29, 35, 0) 0%, #131d23 100%);
    position: absolute;
    bottom: 0;
    z-index: 1;
}

.inner-overlay2 {
    height: 120px;
    width: 93%;
    background: linear-gradient(180deg, rgba(19, 29, 35, 0) 0%, #131d23 100%);
    position: absolute;
    bottom: 6px;
    z-index: 1;
    left: 15px;
}

.inner-overlay3 {
    height: 120px;
    width: 95%;
    background: linear-gradient(180deg, rgba(19, 29, 35, 0) 0%, #131d23 100%);
    position: absolute;
    bottom: 7px;
    z-index: 1;
    left: 0px;
}

.inner-overlay4 {
    height: 120px;
    width: 98%;
    background: linear-gradient(180deg, rgba(19, 29, 35, 0) 0%, #131d23 100%);
    position: absolute;
    bottom: 6px;
    z-index: 1;
    left: 0px;
}

.inner-overlay5 {
    height: 120px;
    width: 95%;
    background: linear-gradient(180deg, rgba(19, 29, 35, 0) 0%, #131d23 100%);
    position: absolute;
    bottom: 6px;
    z-index: 1;
    left: 0px;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #b30000;
}

/*MODAL FADE LEFT BOTTOM */

.modal.left {
    font-family: Kiron, serif;
}

.modal.left .modal-dialog {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    min-width: 300px;
}

.modal.left .modal-dialog.modal-sm {
    max-width: 300px;
    height: 100vh;
}

.modal.left .modal-content {
    min-height: 100vh;
    border: 0;
    height: 100vh;
    display: block;
    border-radius: 0;
    overflow: hidden;
}

.modal.left .modal-header {
    height: 75px;
}

.modal.left .modal-body {
    height: calc(100vh - 58px);
    overflow: auto;
}

.mobile-menu {
    list-style: none !important;
    padding-left: 5px;
}

.mobile-menu-sub {
    list-style: none !important;
    padding-left: 10px;
}

.mobile-menu li {
    font-size: 20px;
    line-height: 39px;
    list-style: none !important;
    border-bottom: 1px solid #e2e2e2;
}
.mobile-menu li:last-child {
    border-bottom: unset;
}
.mobile-menu-sub li {
    font-size: 20px;
    list-style: none !important;
    padding-left: 1px;
    line-height: 20px;
}
.mobile-menu-sub li:last-child {
    border-bottom: unset;
}

.mobile-menu a {
    padding: 6px 8px 6px 2px;
    text-decoration: none;
    font-size: 18px;
    color: #818181;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
}

.mobile-menu-sub a {
    text-decoration: none;
    font-size: 20px;
    color: #818181;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
}
.sidebar-share {
    padding-left: 20px;
    text-align: center;
}
.sidebar-share h5 {
    font-family: 'SolaimanLipi';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
}
.sidebar-share ul {
    padding-left: 0;
}
.sidebar-share ul li {
    display: inline-block;
    margin-right: 20px;
}

.modal.fade .modal-dialog.modal-dialog-slideout {
    transform: translate(-100%, 0);
}
.modal.fade.show .modal-dialog.modal-dialog-slideout {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    flex-flow: column;
}

.search-data-n-found {
    text-align: center;
    margin: auto;
}

.search-data-n-found-h {
    margin-top: 20px;
    font-size: 24px;
    color: #212121;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
}

.search-data-n-found-b {
    margin-top: 10px;
    font-size: 16px;
    color: #757575;
    line-height: 24px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
}

.search-data-n-found-i {
    margin: 0 auto;
    width: 100%;
    margin-bottom: 24px;
}
.search-page-input {
    width: 100%;
}
.search-page-input > .header-search-group {
    width: 100%;
}
.search-page-input > .header-search-group > .header-search-text-input {
    width: calc(100% - 40px);
}
.search-page-sidebar-title {
    padding-bottom: 15px;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.125); */
}
.search-page-sidebar-title > button {
    padding: 4px 10px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    background: none;
    float: right;
    display: none;
}
.search-cancel-btn {
    display: none;
}
.search-page-sidebar > .accordion > .card {
    background: unset;
    border-radius: unset;
    border: unset;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.search-page-sidebar > .accordion > .card:last-child {
    border-bottom: unset;
}
.search-page-sidebar > .accordion > .card > .card-header {
    background: unset;
    border-bottom: unset;
    border-radius: unset;
    padding-left: 0;
    padding-right: 0;
}
.search-page-sidebar > .accordion > .card > .card-header > button {
    background: unset;
    text-decoration: none;
    width: 100%;
    text-align: left;
    color: #000;
    padding-left: 0;
    padding-right: 2px;
}
.search-page-sidebar > .accordion > .card > .card-header > button:focus {
    box-shadow: unset !important;
}
.search-page-sidebar > .accordion > .card .card-body {
    max-height: 300px;
    overflow: scroll;
}

.search-page-sidebar > .accordion > .card .card-body::-webkit-scrollbar {
    display: none;
}

.search-page-sidebar > .accordion > .card .card-body {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.search-section-box {
    overflow: hidden;
    display: flex;
    margin-bottom: 24px;
    padding: 10px 10px 10px 0;
    border-bottom: 1px solid var(--color-border-light);
    box-sizing: border-box;
}
.search-section-box-info {
    flex: 1 1 auto;
    padding-right: 15px;
}
.search-section-box-info > h4 {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 22px;
    color: var(--color-black);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.search-section-box-info > h5 {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    color: var(--color-black);
    opacity: 0.7;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.search-section-box-img {
    flex: 0 0 35%;
    width: 35%;
}
.search-section-box-img img {
    width: 100%;
}
.search-section-date-input {
    display: flex;
    align-items: center;
}
.search-section-date-input span {
    padding: 0 10px;
}
.more-photos {
    width: 100%;
    margin-bottom: 24px;
}

.mujib-year {
    width: 139px;
    float: right;
    position: relative;
    top: 5px;
}

.gallery-container {
    background-color: #fff;
    color: #35373a;
    min-height: 100vh;
    padding: 30px 50px;
}

.gallery-img {
    width: 100%;
    height: 200px;
}

.gallery-img > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.tz-gallery {
    padding-left: 14px;
    padding-right: 14px;
    margin-bottom: 24px;
}

.gallery-title {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.tz-gallery-img-title {
    position: absolute;
    z-index: 1;
    color: white;
    bottom: 14px;
    width: 90%;
    left: 25px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 40px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

/* Override bootstrap column paddings */

.tz-gallery .row > div {
    padding: 2px;
}

.tz-gallery .lightbox img {
    width: 100%;
    border-radius: 0;
    position: relative;
}

/* 
.tz-gallery .lightbox:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-color: rgba(46, 132, 206, 0.7);
    content: "";
    transition: 0.4s;
} */

/* 
.tz-gallery .lightbox:hover:after,
.tz-gallery .lightbox:hover:before {
    opacity: 1;
} */

.baguetteBox-button {
    background-color: transparent !important;
}

.tz-image {
    padding-left: 12px;
    padding-right: 12px;
}

.lightbox > img {
    width: 100%;
    height: 100%;
}

.details-news-body-detail p img {
    max-width: 100%;
}

/* Image Aria  */

.link-area .image-area {
    position: relative;
}

.link-area .image-area img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.play-button {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 48px;
    height: 36px;
    background: url(../images/Youtube-black.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transform: translateX(-50%) translateY(-50%);
}

.link-area:hover .play-button {
    background-image: url(../images/Youtube.svg);
}

.bottom-news-body-img .play-button,
.update-news-body .play-button {
    width: 32px;
    height: 24px;
}

.hero-news-short-img .play-button,
.hero-news-short-play-img .play-button {
    width: 84px;
    height: 36px;
}

.ads-01 {
    text-align: center;
    margin-bottom: 15px;
    margin-top: 15px;
}

.ads-01 img {
    max-width: 90%;
}

.raw-html-embed {
    /* background-color: #d9dddb; */
    text-align: center;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.raw-html-embed iframe {
    /* max-width: 100% !important; */
    display: block;
    margin: 0px auto;
}

.twitter-tweet,
.twitter-tweet-rendered {
    margin: auto;
}

.loading-news {
    height: 500px;
    background: #f5f5f5;
}

.load-wraper {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: rgb(211, 211, 211);
    z-index: 44;
    overflow: hidden;
    border-radius: 5px;
}

.activity {
    position: absolute;
    left: -45%;
    height: 100%;
    width: 45%;
    background-image: linear-gradient(
        to left,
        rgba(251, 251, 251, 0.05),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.6),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.05)
    );
    background-image: -moz-linear-gradient(
        to left,
        rgba(251, 251, 251, 0.05),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.6),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.05)
    );
    background-image: -webkit-linear-gradient(
        to left,
        rgba(251, 251, 251, 0.05),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.6),
        rgba(251, 251, 251, 0.3),
        rgba(251, 251, 251, 0.05)
    );
    animation: loading 1s infinite;
    z-index: 45;
}

@keyframes loading {
    0% {
        left: -45%;
    }
    100% {
        left: 100%;
    }
}

.notification-modal .modal-body {
    padding: 0;
}

.notification-modal .modal-content {
    border: 0px solid;
}

.notification-set-window-modal {
    background: #fff;
    padding: 20px;
    box-shadow: 0 0 15px -5px #000;
    border-radius: 20px;
    width: 300px;
    z-index: 2000;
    text-align: center;
    font-family: 'SolaimanLipi', sans-serif;
    margin-left: 10%;
}

.notification-set-window-modal-content {
    background: none;
}

.notification-set-window-modal-backdrop {
    display: none !important;
}

.notification-set-window {
    position: fixed;
    bottom: 124px;
    right: 10px;
    width: 300px;
    background: #fff;
    padding: 20px;
    box-shadow: 0 0 15px -5px #000;
    border-radius: 20px;
    /* -webkit-transform: translateX(-50%); */
    /* transform: translateX(-50%); */
    z-index: 2000;
    text-align: center;
    font-family: 'SolaimanLipi', sans-serif;
}

.notification-set-window img {
    width: 67%;
}

.notification-set-window button {
    width: 50px;
}

.online.economics,
.online.crime {
    margin-bottom: 9px !important;
}

.online.national,
.online.politics,
.online.bangladesh,
.online.international {
    margin-bottom: 10px !important;
}

.t20-ads {
    position: relative;
    margin: 0px auto;
    /* margin-top: 20px; */
    width: 728px;
    height: 110px;
    overflow: hidden;
}

.t20-ads.sm {
    width: 300px;
    height: 250px;
    display: none;
}

.t20-ads img {
    position: absolute;
}

.t20-ads .info {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #ffffff;
    box-sizing: border-box;
    border-radius: 8px;
    height: 74px;
    width: 200px;
    z-index: 7;
    position: absolute;
    top: 22px;
    left: 35%;
    text-align: center;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: bold;
}

.t20-ads.sm .info {
    left: 50%;
    transform: translateX(-50%);
    top: 64px;
}

.t20-ads .txt-0 {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
    background: #10044a;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    display: block;
    width: 25px;
    height: 25px;
    text-align: center;
    border-radius: 50px;
    position: absolute;
    left: 50%;
    top: -13px;
    transform: translateX(-50%);
}

.t20-ads .txt-1 {
    font-size: 15px;
    line-height: 22px;
    color: #ffffff;
    display: block;
    margin-top: 18px;
}

.t20-ads .txt-2 {
    font-size: 12px;
    line-height: 16px;
    color: #29ef82;
}

.regModal {
    width: 550px;
    height: auto;
    border: 1px solid #d5d5d5;
    box-sizing: border-box;
    margin: auto;
}

.regModal > h6 {
    text-align: center;
}

.loginModal {
    width: 550px;
    border: 1px solid #d5d5d5;
    box-sizing: border-box;
    margin: auto;
}

.loginModal > h6 {
    text-align: center;
}

.loginModal-header {
    padding: 25px;
}

.loginModal-logo {
    margin: auto;
    width: 157px;
    height: 67px;
    margin-bottom: 22px;
}

.loginModal-logo > img {
    width: 157px;
}

.loginModal-title {
    width: 275px;
    margin: auto;
    text-align: center;
    margin-bottom: 28px;
}

.loginModal-social-btn {
    margin: auto;
}

.loginModal-fb-btn {
    margin: auto;
    width: 408px;
    height: 50px;
    background: #1877f2;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
}

.loginModal-fb-btn-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    margin-left: 3%;
    margin-top: 3%;
}

.loginModal-fb-btn-icon > img {
    margin-left: 37%;
}

.loginModal-fb-btn-text {
    padding: 12px;
    margin-left: 50px;
    color: white;
}

.loginModal-google-btn {
    margin: auto;
    width: 408px;
    height: 50px;
    background: #ea4335;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
}

.loginModal-google-btn-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    margin-left: 3%;
    margin-top: 3%;
}

.loginModal-google-btn-icon > img {
    margin-left: 26%;
}

.loginModal-google-btn-text {
    padding: 12px;
    margin-left: 50px;
    color: white;
}

.loginModal-twtr-btn {
    margin: auto;
    width: 408px;
    height: 50px;
    background: #1d9bf0;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
}

.loginModal-twtr-btn-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    margin-left: 3%;
    margin-top: 3%;
}

.loginModal-twtr-btn-icon > img {
    margin-left: 27%;
}

.loginModal-twtr-btn-text {
    padding: 12px;
    margin-left: 50px;
    color: white;
}

.loginModal-email-input {
    margin: auto;
    width: 408px;
    height: 50px;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    border: 1px solid #dbdbdb;
    overflow: hidden;
}

.loginModal-email-input > input {
    border: none;
    margin-left: 10px;
    width: 100%;
    background: none;
}

.loginModal-email-input > input:active {
    border: none;
    margin-left: 10px;
    width: 100%;
    background: none;
}

.loginModal-email-input > input:focus {
    border: none;
    margin-left: 10px;
    width: 100%;
    background: none;
}

.loginModal-email-input > input:focus-visible {
    border: none !important;
    margin-left: 10px;
    width: 100%;
    background: none;
    outline: none;
}

.loginModal-email-input-warning {
    position: relative;
    top: -20px;
    left: 13%;
    color: red;
}

.loginModal-login-btn {
    margin: auto;
    width: 408px;
    height: 50px;
    background: #ea4335;
    border-radius: 10px;
    margin-bottom: 20px;
    cursor: pointer;
}

.loginModal-login-btn-text {
    text-align: center;
    color: white;
    position: relative;
    top: 25%;
}

.loginModal-login-footer {
    margin: auto;
    text-align: center;
    margin-bottom: 25px;
}

.loginModal-login-footer > h5 {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
    color: #006837;
}

.loginModal-login-footer > h6 {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
}

.loginModal-login-footer > h6 > span {
    color: red;
}

.modal-100wV {
    max-width: 100% !important;
    margin: 0 !important;
}

.modal-content-custom {
    border: none !important;
    border-radius: 0 !important;
    background-clip: unset !important;
}

.details-share-box-modal {
    height: 225px;
    width: 450px;
    background: #ffffff;
    box-shadow: 0px 0px 35px rgba(17, 17, 17, 0.1);
    border-radius: 15px;
    position: fixed;
    margin-top: -345px;
    right: 30px;
}

.details-share-box-modal::after {
    content: '';
    position: absolute;
    width: 27px;
    height: 19.29px;
    bottom: -13px;
    right: -4px;
    background: #ffffff;
    transform-origin: 0 0;
    transform: rotate(135deg);
}

.details-share-box {
    height: 225px;
    width: 450px;
    background: #ffffff;
    box-shadow: 0px 0px 35px rgba(17, 17, 17, 0.1);
    border-radius: 15px;
    position: absolute;
    z-index: 1;
    margin-top: 20px;
    right: 80px;
}

.details-share-box::before {
    content: '';
    position: absolute;
    width: 27px;
    height: 19.29px;
    top: 2px;
    right: -4px;
    background: #ffffff;
    transform-origin: 0 0;
    transform: rotate(135deg);
}

.details-share-box-table {
    margin: 10px;
    height: 190px;
}

.details-share-box-tr {
    padding-top: 10px;
    padding-bottom: 20px;
}

.details-share-box-tr > td {
    padding-right: 24px;
}

.details-share-box-tr > td > span {
    margin-top: 10px;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    text-align: justify;
    color: #111111;
}
.details-share-box-modal > table > tbody > tr > td > button {
    margin-right: 10px;
}
.share-print-btn {
    background-color: #7f7f7f;
    border: none;
}

.share-insta-btn {
    background-image: radial-gradient(
        111.73% 111.73% at 69.27% 94.6%,
        rgba(140, 58, 170, 0) 64%,
        #8c3aaa 100%
    );
    border-radius: 67px;
}

.share-save-btn {
    background-color: red;
    border: none;
}

.ads-seven {
    text-align: center;
    margin-bottom: 24px;
}

.sidebar-converter {
    margin-bottom: 24px;
}

.sidebar-add-eight {
    width: 100%;
    height: 250px;
    overflow: hidden;
}

.add-nine {
    width: 100%;
    overflow: hidden;
    margin-bottom: 24px;
}

.add-nine-show {
    display: none;
}

.modal-header > .close > span {
    font-size: 13px;
}

.modal-header > .close > span :hover {
    font-size: 13px;
    color: white;
}

button.close {
    border: 1px solid;
    background: none;
    border-radius: 50px;
    padding: 0 12px;
    display: flex;
}

button.close span:first-child {
    position: relative;
    top: 2px;
    padding-right: -1px;
}

.updated-news-op {
    margin: 0 10px;
}

.article-list .article-list-item {
    height: 70px;
}

.article-list-item {
    margin: 0 10px 10px 10px;
    display: block;
    height: 63px;
    font-family: 'SolaimanLipi', sans-serif;
}

.article-list-item .image-area {
    text-align: center;
}

.article-list-item .text-area {
    line-height: 20px;
    font-size: 15px;
}
.article-list-item .text-area span {
    color: var(--color-red);
}
.article-list-item:hover .text-area {
    color: var(--color-hover);
}
.article-list-item:hover .text-area span {
    color: var(--color-hover);
}
.article-list-item .image-area img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
}

.welcome-modal .welcome-modal-header.modal-header {
    /* overflow: hidden; */
    height: 0;
    padding: 0;
}

.welcome-modal .welcome-image {
    overflow: hidden;
    border-radius: 10px;
}

.welcome-modal .welcome-image img {
    width: 100%;
}

.welcome-modal .modal-body {
    padding: 0;
}

.welcome-modal .modal-content {
    border: 0px solid;
}

.welcome-modal button.close {
    position: absolute !important;
    right: -8px;
    z-index: 1000;
    top: -10px !important;
    background: #fff;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0px;
    display: block;
    box-shadow: 0 0 20px 2px #4c4c4c54;
}

.welcome-modal .close .sr-only {
    display: none;
}

.welcome-modal .close span {
    padding: 0 !important;
    top: -2px !important;
}

.welcome-modal .welcome-modal-header.modal-header {
    position: relative;
}

.welcome-modal .modal-header {
    border: 0px solid;
}

.welcome-modal .modal-content {
    border-radius: 10px;
}

.welcome-modal {
    background: #000000bf !important;
    /* filter: blur(4px); */
    /* backdrop-filter: blur(17px); */
}

.button-copy {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    padding: 0 7px;
    box-sizing: border-box;
}

.animate {
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}

.scroll-to-top {
    position: fixed;
    bottom: 110px;
    right: 20px;
    z-index: 1000;
    width: 37px;
    opacity: 0;
    color: #07a75c;
    z-index: 9999;
}

.scroll-to-top.show {
    opacity: 1;
}

.menu-top-publicity {
    text-align: center;
    position: relative;
}

.menu-top-cross {
    width: 99px;
    height: 30px;
    position: absolute;
    right: 76px;
    top: 0;
}

.menu-top-cross > button {
    border: none;
    background: red;
    color: white;
}

.modal-backdrop {
    display: none !important;
}

.print-footer {
    display: none;
}

.dialog-modal-video {
    justify-content: center;
}

.modal-content-video {
    width: auto !important;
    border: none !important;
    background-color: transparent !important;
}

.modal-close-video {
    width: 30px;
    height: 30px;
    background-image: url('../images/video-close-icon.svg');
    float: right;
    position: relative;
    right: -15px;
    top: 15px;
    cursor: pointer;
}

.modal-body-video {
    padding: 0 !important;
}

.modal-backdrop-video {
    display: block !important;
}

.modal-backdrop-login {
    display: block !important;
}

.modal-responsive-video {
    width: 853px;
    height: 480px;
}

/* Timeline Flow News*/

.flow-details-news {
    grid-template-columns: auto 25.62%;
    padding: 5px 20px 20px 20px;
    background-color: #f2f3fe;
    margin-bottom: 24px;
}
.flow-details-news-title {
    margin-bottom: 24px;
}
.flow-details-news-title > h1 {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 49px;
    color: var(--color-black);
}
.flow-details-news-title > img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    object-position: top;
}
.flow-sidebar-nav {
    position: sticky;
    top: 50px;
    bottom: 50px;
}

.flow-sidebar-title {
    text-decoration: none;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 49px;
    color: var(--color-black);
    margin-left: 10px;
    margin-bottom: 20px;
}

.timeline {
    border-left: 3px solid #727cf5;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 auto;
    letter-spacing: 0.2px;
    position: relative;
    line-height: 1.4em;
    font-size: 1.03em;
    list-style: none;
    text-align: left;
    max-width: 90%;
    padding-left: 12px;
}

@media (max-width: 767px) {
    .timeline {
        max-width: 98%;
        padding: 25px;
    }
}

.timeline p {
    font-family: 'SolaimanLipi', sans-serif;
    position: relative;
    top: -7px;
}

.timeline h1 {
    font-weight: 300;
    font-size: 1.4em;
    font-family: 'SolaimanLipi', sans-serif;
}

.timeline h2,
.timeline h3 {
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 10px;
    font-family: 'SolaimanLipi', sans-serif;
}

.timeline .event {
    border-bottom: 1px dashed #e8ebf1;
    padding-bottom: 25px;
    margin-bottom: 25px;
    position: relative;
}

@media (max-width: 767px) {
    .timeline .event {
        padding-top: 30px;
    }
}

.timeline .event:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
}

.timeline .event:before,
.timeline .event:after {
    position: absolute;
    display: block;
    top: 0;
}

/* .timeline .event:before {
  left: -207px;
  content: attr(data-date);
  text-align: right;
  font-weight: 100;
  font-size: 0.9em;
  min-width: 120px;
} */

@media (max-width: 767px) {
    .timeline .event:before {
        left: 0px;
        text-align: left;
    }
}

.timeline .event:after {
    -webkit-box-shadow: 0 0 0 3px #727cf5;
    box-shadow: 0 0 0 3px #727cf5;
    left: -17.8px;
    background: #fff;
    border-radius: 50%;
    height: 9px;
    width: 9px;
    content: '';
}

@media (max-width: 767px) {
    .timeline .event:after {
        left: -31.8px;
    }
}

.rtl .timeline {
    border-left: 0;
    text-align: right;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border-right: 3px solid #727cf5;
}

.rtl .timeline .event::before {
    left: 0;
    right: -170px;
}

.rtl .timeline .event::after {
    left: 0;
    right: -55.8px;
}

.bik-lb {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11000;
    background: #000;
}

.lb-sliding {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    z-index: 2;
}

.lb-sliding > figure {
    min-width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    opacity: 0;
    z-index: 1;
    transition: 2s opacity ease-in-out;
}

.lb-sliding > figure.active {
    opacity: 1;
    z-index: 2;
}

.lb-sliding > figure > img {
    object-fit: contain;
    object-position: center;
    width: 1200px;
    height: 100%;
    background: #000;
}

.lb-sliding > figure > figcaption {
    position: absolute;
    bottom: 0;
    width: 1200px;
    transform: translateX(-50%);
    left: 50%;
    background: #00000070;
    color: #fff;
    padding: 22px;
    border: 0px solid #000;
}

.lb-navigator {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    color: #fff;
    display: flex;
    padding-right: 10px;
    padding-top: 5px;
}

.lb-navigator-counter {
    margin-right: 10px;
    padding-top: 3px;
}

.lb-navigator-close {
    cursor: pointer;
}

.lb-navigator-custom {
    display: flex;
    top: 50%;
    position: absolute;
    width: 100%;
}

.lb-navigator-left {
    width: 85%;
    z-index: 999;
    padding-left: 10px;
    cursor: pointer;
}

.lb-navigator-right {
    width: 15%;
    text-align: right;
    z-index: 999;
    padding-right: 10px;
    cursor: pointer;
}

.custom-login-input {
    margin-bottom: 14px;
}

.custom-login-other {
    margin-bottom: 14px;
}

.custom-login-other > a {
    float: right;
    font-family: Jost;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #00a3ff;
}

.custom-login-other-label > label {
    float: right;
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #535f82;
    position: relative;
    top: 3px;
}

.custom-label {
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071b;
    opacity: 0.9;
}

.custom-input {
    width: 100%;
    height: 50px;
    background: #ffffff;
    border: 1px solid rgb(0 7 27 / 10%);
    box-sizing: border-box;
}

.custom-input:focus {
    border: 1px solid rgb(235, 33, 33);
    background: none !important;
}

.custom-input-otp {
    height: 50px;
    background: #ffffff;
    border: 1px solid #e5e6e8;
    box-sizing: border-box;
    margin-bottom: 20px;
    letter-spacing: 20px;
    font-size: 30px;
}

.custom-input-otp::-webkit-outer-spin-button,
.custom-input-otp::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

/* .custom-input-otp[type='number'] {
    -moz-appearance: textfield;
    /* Firefox * /
} */

.custom-input-otp:focus {
    border: 1px solid rgb(235, 33, 33);
    background: none !important;
}

.custom-otp-title {
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #00071b;
    opacity: 0.9;
    margin-bottom: 20px;
}

.custom-otp-expire {
    margin-bottom: 11px;
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #00071b;
    margin-bottom: 20px;
}

.custom-otp-expire > b {
    color: red;
}

.custom-verify-btn {
    width: 100%;
    height: 50px;
    background: #fe0002;
    border: none;
    margin-bottom: 20px;
    color: white;
}

.custom-resend-btn {
    width: 100%;
    height: 50px;
    background: #ffffff;
    border: 1px solid #e5e6e8;
    box-sizing: border-box;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.custom-resend-btn > p {
    width: 90%;
    color: #fe0002;
    text-align: center;
    height: 13px;
}

.custom-resend-btn > div {
    width: 26px;
    height: 26px;
    border: 1px solid rgba(254, 0, 2, 0.5);
    box-sizing: border-box;
    border-radius: 50%;
    font-size: 14px;
    line-height: 20px;
    color: #fe0002;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-resend-btn:disabled p {
    color: #e5e6e8;
}

.custom-login-btn {
    width: 100%;
    height: 50px;
    border: none;
    background: #fe0002;
    margin-bottom: 14px;
}

.custom-login-btn:hover {
    background: #ce0406 !important;
}

.custom-login-social {
    margin-bottom: 24px;
}

.custom-login-btn-google {
    width: 30%;
    height: 50px;
    background: #ea4335;
    margin-right: 5%;
    border: none !important;
}

.custom-login-btn-facebook {
    width: 30%;
    height: 50px;
    background: #1877f2;
    margin-right: 5%;
    border: none !important;
}

.custom-login-btn-twitter {
    width: 30%;
    height: 50px;
    background: #1da1f2;
    border: none !important;
}

.custom-login-btn-google:hover {
    background: #c2382c;
}

.custom-login-btn-facebook:hover {
    background: #1468d4;
}

.custom-login-btn-twitter:hover {
    background: #178dd6;
}

.custom-login-footer > p {
    font-family: Jost;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    color: #1a2032;
}

.custom-login-footer > p > span {
    font-family: Jost;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #fe0002;
    cursor: pointer;
}

.btn-close-custom .close {
    width: 30px;
    height: 30px;
    background: rgba(255, 27, 40, 0.1);
    border: 1px solid #fbcfd3;
    box-sizing: border-box;
    border-radius: 30px;
    background-image: url('../images/IconCross.svg');
    background-repeat: no-repeat;
    background-position: center;
    position: initial;
    margin-top: 5px;
}

.btn-close-custom .close span {
    display: none;
}

/* lineup class and keyframes */

.lineUp {
    animation: 4s anim-lineUp ease-out infinite;
    font-size: 22px;
    padding-top: 8px;
}

.lineUp a {
    color: red;
}

@keyframes anim-lineUp {
    0% {
        opacity: 0;
        transform: translateY(80%);
    }
    20% {
        opacity: 0;
    }
    50% {
        opacity: 1;
        transform: translateY(0%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}
.top-notification {
    width: 100%;
    padding: 0 20px 10px 0;
    height: 390px;
    overflow: auto;
}
.top-notification ul li {
    list-style: none;
    border-bottom: 1px solid #cccccc;
    padding-top: 10px;
}
.top-notification ul li:first-child {
    border-bottom: none;
    padding-top: 3px;
}
.top-notification ul li a {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 27px;
    color: var(--color-black);
    opacity: 0.7;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.top-notification ul li p {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 27px;
    color: rgba(17, 17, 17, 0.4);
    line-height: 22px;
    margin-bottom: 6px;
}
.top-notification ul li p:first-child {
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 27px;
    cursor: pointer;
    color: #111111;
}

.top-notification-btn {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding-bottom: 20px;
    height: 50px;
}
.top-notification-btn a {
    width: 100px;
    text-align: center;
    font-family: 'SolaimanLipi', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 27px;
    color: #a8a8a8;
    border: 1px solid #cccccc;
}
.football-banner {
    height: 350px;
    background-color: #ffecf2;
    margin-bottom: 24px;
    width: 100%;
}
.football-banner-header {
    width: 100%;
    cursor: pointer;
}
.football-banner-header img {
    width: 100%;
}
.football-banner-menu {
    padding: 10px 0;
}
.football-banner-menu ul {
    padding-left: 0;
    margin-bottom: 0;
}
.football-banner-menu ul li {
    display: inline-flex;
    width: 46%;
    height: 45px;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.04);
    border-radius: 6px;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
    font-family: 'SolaimanLipi';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    cursor: pointer;
    color: #000000;
}
.football-banner-menu ul li > img {
    margin-right: 10px;
}
.football-banner-divider {
    padding: 0 10px;
}
.football-banner-divider div {
    width: 100%;
    height: 2px;
    background: #aa0a3c;
}
.football-banner-title {
    text-align: center;
    padding: 16px 0 12px 0;
    font-family: 'SolaimanLipi';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    /* identical to box height, or 100% */

    color: #000000;
}
.football-banner-body {
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 10px;
}
.football-banner-body > .item {
    width: 49%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 0;
}
.football-banner-body > .item:nth-child(1) {
    border-right: 1px solid #ffd0d0;
}

/* .football-banner-body > .item:nth-child(2) {
} */

.football-banner-body > .item:nth-child(3) {
    border-right: 1px solid #ffd0d0;
    padding-bottom: 0;
    border-top: 1px solid #ffd0d0;
}
.football-banner-body > .item:nth-child(4) {
    padding-bottom: 0;
    border-top: 1px solid #ffd0d0;
}
.gapDesign {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #ffecf2;
    top: 58px;
    left: 46%;
}
.item-country {
    width: 33%;
    text-align: center;
    padding: 0 5px;
}

.item-country-img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    border-radius: 50px;
}

.item-country-img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.item-country p {
    margin-bottom: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #000000;
}
.item-result-info {
    width: 33%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.item-result {
    width: 99%;
    height: 26px;
    border: 1px solid #009f54;
    border-radius: 6px;
    font-family: 'SolaimanLipi';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #006837;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.item-result-single {
    width: 107px;
    height: 56px;
}
.item-result-info > p {
    font-family: 'SolaimanLipi';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    margin-bottom: 0;
}
.football-banner-footer {
    text-align: center;
}
.football-banner-footer a {
    font-family: 'SolaimanLipi';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    height: 30px;
    background: #006837;
    border-radius: 50px;
    padding: 5px 20px;
}
/* .football-fixture {
} */

.football-fixture-title {
    height: 80px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../images/HeaderPageBanner.svg');
}
.football-fixture-title > h3 {
    font-family: 'SolaimanLipi';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 53px;
    color: #000000;
}
.football-fixture-title > h5 {
    font-family: 'SolaimanLipi';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
}
.football-fixture-title-bb {
    width: 50%;
    height: 3px;
    background: #f70f21;
}

.football-fixture-body {
    width: 100%;
    border: 1px solid #ffcadb;
    border-radius: 10px;
    margin-bottom: 30px;
}

.football-fixture-body-title {
    width: 100%;
    height: 50px;
    background-color: #ffcadb;
    font-family: 'SolaimanLipi';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #111111;
    display: flex;
    align-items: center;
    padding-left: 20px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.football-fixture-item {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    position: relative;
}
.fixture-item {
    width: 50%;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.fixture-item:nth-child(1) {
    border-right: 1px solid #ffd0d0;
}

/* .fixture-item:nth-child(2) {
} */

.fixture-item:nth-child(3) {
    border-right: 1px solid #ffd0d0;
    padding-bottom: 0;
    border-top: 1px solid #ffd0d0;
}
.fixture-item:nth-child(4) {
    padding-bottom: 0;
    border-top: 1px solid #ffd0d0;
}

.fixture-item-c:nth-child(3) {
    width: 100%;
    border-right: unset;
}
.fixture-item-country {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 21px;
    width: 100%;
}

.fixture-item-country > h4 {
    font-family: 'SolaimanLipi';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    color: #000000;
    width: calc(50% - 83px);
}
.fixture-item-country > h5 {
    font-family: 'SolaimanLipi';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    color: #000000;
    display: flex;
    justify-content: center;
}
.fixture-item-country > img {
    width: 63px;
    height: 40px;
    margin-right: 20px;
    margin-left: 20px;
    border: 1px solid #fff0bb;
    border-radius: 6px;
}

.fixture-item-country div {
    width: 15px;
    height: 1px;
    background-color: #000;
}
.fixture-item-time {
    background: #ffebec;
    border-radius: 7px;
    font-family: 'SolaimanLipi';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    padding: 7px 15px;
}
.fixture-item-time > p {
    margin-bottom: 0;
}
.fixture-item-gap-design {
    position: absolute;
    background-color: #ffffff;
    width: 30px;
    height: 30px;
    top: 155px;
    left: 48.7%;
}

.football-point {
    width: 100%;
}
.football-point-tbl {
    margin-bottom: 32px;
    width: 100%;
}
.football-point-tbl-title {
    font-family: 'SolaimanLipi';
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 66px;
    color: #000000;
}

.football-point-tbl-title > span {
    color: red;
}
.football-point-tbl table {
    width: 100%;
}

.football-point-tbl table > thead {
    background-color: #ffecf2;
}

.football-point-tbl table > thead > tr > th {
    font-family: 'SolaimanLipi';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #111111;
    padding: 10px 20px;
    text-align: center;
    width: 8.75%;
}
.football-point-tbl-thead > tr > th {
    padding-bottom: 0 !important;
}
.football-point-tbl table > thead > tr > th:first-child {
    width: 30%;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    text-align: left;
}

.football-point-tbl table > thead > tr > th:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.football-point-tbl table > tbody > tr {
    border-bottom: 1px solid rgba(128, 128, 128, 0.219);
}
.football-point-tbl table > tbody > tr > td {
    font-family: 'SolaimanLipi';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #111111;
    padding: 18px 20px;
    text-align: center;
}

.football-point-tbl table > tbody > tr > td > img {
    width: 40px;
    height: 25px;
    margin-right: 16px;
    border: 1px solid #fff0bb;
    border-radius: 4px;
}
.football-point-tbl table > tbody > tr > td:first-child {
    font-size: 20px;
    line-height: 20px;
    text-align: left;
}
.football-fixture-breadcumb {
    margin-bottom: 30px;
}
.football-fixture-breadcumb > ul {
    padding-left: 0;
}
.football-fixture-breadcumb > ul > li {
    display: inline-block;
    margin-right: 10px;
    list-style: circle;
    font-family: 'SolaimanLipi';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
}
.football-fixture-breadcumb > ul li:before {
    content: '• ';
}
.football-fixture-breadcumb > ul li:first-child:before {
    content: unset;
}
.football-fixture-tab {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.football-fixture-tab > button {
    background-color: #ffcadb;
    border: unset;
    height: 50px;
    width: 25%;
    margin-bottom: 30px;
    font-family: 'SolaimanLipi';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
}
.football-fixture-tab > button:first-child {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}
.football-fixture-tab > button:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}
.football-fixture-tab > .active {
    background-color: #8c0f36;
    color: white;
}
.item-c:nth-child(3) {
    border-right: unset !important;
    width: 100% !important;
}
.item-c:nth-child(3) > .item-country {
    width: 52px !important;
}
.item-c:nth-child(3) > .item-result-info {
    width: 75px !important;
}
.search-author-input {
    position: relative;
    top: -15px;
    width: 100%;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    height: 40px;
    padding-left: 10px;
}
.search-author-input:focus-visible {
    outline: unset;
}
.height-130 {
    height: 130px;
}
.accordion-button:not(.collapsed) {
    background-color: unset !important;
    box-shadow: unset !important;
    border-radius: unset !important;
}
.accordion-item:first-of-type {
    border-radius: unset !important;
    border: unset !important;
}
.accordion-item {
    border: unset !important;
}
.accordion-header {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-button:focus {
    border: unset !important;
    border-color: transparent !important;
    box-shadow: unset !important;
}
.accordion-button {
    padding-left: 0 !important;
}
.welcome-modal-header2 > .btn-close {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    z-index: 9999;
    opacity: 1;
}
.welcome-modal2 .modal-content {
    background-color: unset !important;
}

.video-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
}
