/* :root {
    --color-base: #006837;
    --color-black: black;
    --color-white: white;
    --color-red: red;
    --color-yellow: #f3bf05;
    --color-link: #006837;
    --color-link-aria: red;
    --color-border: #e5e5e5;
    --color-border-light: #e7e7e7;
    --color-h-search-send: #006837;
    --color-menu-bg: #006837;
    --color-header-menu-bg: #f81020;
    --color-header-menu-hover: #1a774b;
    --color-header-menu-active: #ec2028;
    --color-hero-news-body-border: #e5e5e5;
    --color-update-news-title-bg: #f1e8f6;
    --color-update-news-title-text: #7719aa;
    --color-home-category-news-title-bg: #e8f2fa;
    --color-details-news-tag-bg: #e6e6e6;
    --color-details-news-tag-text: #1879d0;
    --color-home-catefory-news-bg: linear-gradient( 317deg, transparent 30px, #006837 31px);
    --color-top-h-border: #0000001a;
    --color-not-found-404-btn: #e5efea;
    --color-footer-fst: linear-gradient(90deg, #00542c 0%, #000f08 100%);
    --color-footer-3rd: linear-gradient(90deg, #00542c 0%, #000f08 100%);
    --color-footer-full-menu: linear-gradient(to bottom, white, rgba(0, 0, 0, 0)) 1 100%;
    --coolor-footer-dev-text: #00ff87;
    --color-header-top-bar-icon: rgba(17, 17, 17, 0.3);
    --color-top-menu-modal-body: #f2f2f2;
    --color-figcaption: #ffe1e1;
    --color-tv-style-shadow: #999;
} */
@media (min-width: 1900px) {
    .sidebar-desktop-cross {
        display: none;
    }
    .header-desktop-cross {
        display: block;
    }
}
@media (min-width: 1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1330px;
    }
    .hero-news-short-img {
        height: 151px;
    }
    .hero-news-short {
        height: 226px;
    }
    button.close span:first-child {
        top: -1px;
    }
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .container {
        max-width: 1180px;
    }
    .menu-view > ul > li > a {
        font-size: 14px;
        padding: 14px 13px;
    }
    .hero-news-body .image-area {
        height: 266px;
    }
    .hero-news-short-img.image-area {
        height: 135px;
    }
    a.hero-news-short.link-area {
        height: 212px;
    }
    .hero-news-short-play-img {
        height: 136px;
    }
    .hero-news-short-play.link-area {
        height: 241px;
    }
    .cat-news-short-img-detail {
        height: 136px;
    }
    .menu-top-cross {
        right: 50px;
    }
    .header-search-input {
        width: 290px;
        margin-top: 0;
    }
    .header-search-group {
        width: 290px;
    }
    .header-search-text-input {
        width: 250px;
    }
    .menu-view > ul > li {
        display: inline-block;
        margin-right: 2px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    /* .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1199px;
    } */
    .height-130 {
        height: 80px;
    }
    .col.col-840 {
        flex: 65% 1;
        max-width: 65%;
    }
    .col-300-1 {
        flex: 35% 1;
        max-width: 35%;
    }
    .col-300 {
        flex: 50% 1;
        max-width: 50%;
    }
    .col-510 {
        flex: 100% 1;
        max-width: 100%;
    }
    .menu-view > ul > li > a {
        font-size: 14px !important;
    }
    .menu-view > ul > li:nth-child(6),
    .menu-view > ul > li:nth-child(7),
    .menu-view > ul > li:nth-child(12) {
        display: none;
    }
    .update-news-body-img > img {
        width: 90px !important;
    }
    .hero-news-body .image-area {
        height: 315px;
    }
    .sidebar-add-eight {
        height: 237px;
    }
    .hero-news-short-img.image-area {
        height: 95px;
    }
    a.hero-news-short.link-area {
        height: 172px;
    }
    .article-list-item .text-area {
        font-size: 14px;
    }
    button.close span:first-child {
        top: -1px;
    }
    .top-m-menu-col ul {
        padding-left: 15px;
    }
    .top-m-menu-col > ul > li {
        padding: 6px 0px;
    }
    .top-m-menu-col > ul > li > a {
        font-size: 15px;
    }
    .top-menu-modal-body {
        min-height: auto;
    }
    .category-news-title h5 {
        display: none;
    }
    .category-news-img {
        flex: 0 0 200px;
        width: 200px;
    }
    .hero-news-body-title {
        font-size: 22px;
        line-height: 30px;
    }
    .cat-news-short-img-detail {
        height: 95px;
    }
    .details-report-time {
        font-size: 13px;
    }
    .menu-top-cross {
        right: -50px;
    }
    .header-search-input {
        width: 210px;
        margin-top: 0;
    }
    .header-search-group {
        width: 210px;
    }
    .header-search-text-input {
        width: 170px;
    }
}

@media (min-width: 765px) and (max-width: 991px) {
    /* .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 992px;
    } */
    .col.col-840 {
        flex: 100% 1;
        max-width: 100%;
    }
    .col-300-1 {
        flex: 100% 1;
        max-width: 100%;
    }
    .col-300 {
        flex: 50% 1;
        max-width: 50%;
    }
    .col-510 {
        flex: 50% 1;
        max-width: 50%;
    }
    .menu-view > ul > li:nth-child(6),
    .menu-view > ul > li:nth-child(7),
    .menu-view > ul > li:nth-child(8),
    .menu-view > ul > li:nth-child(9),
    .menu-view > ul > li:nth-child(10),
    .menu-view > ul > li:nth-child(11),
    .menu-view > ul > li:nth-child(12) {
        display: none;
    }
    .modal-dialog.modal-90w {
        max-width: 700px;
        margin: 1.75rem auto;
    }

    .top-m-menu-col ul {
        padding-left: 15px;
    }
    .top-m-menu-col > ul > li {
        padding: 6px 0px;
    }
    .top-m-menu-col > ul > li > a {
        font-size: 15px;
    }
    .top-menu-modal-body {
        min-height: auto;
    }
    .update-news-body-img > img {
        height: 44px !important;
        width: 60px !important;
    }
    .update-news-body-title {
        font-size: 14px !important;
        line-height: 19px !important;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }
    .inner-overlay2 {
        width: 100% !important;
    }
    .inner-overlay3 {
        width: 88% !important;
    }
    .inner-overlay4 {
        width: 88% !important;
    }
    .inner-overlay5 {
        height: 120px;
        width: 91%;
    }
    .inner-overlay {
        width: 100% !important;
        right: unset !important;
    }
    .header-top {
        height: 109px;
    }
    .header-more-icon img {
        max-width: 25px;
    }
    .header-top-logo2 > img {
        width: 200px;
    }
    .hero-news-body {
        height: 403px;
    }
    .hero-news-body .image-area {
        height: 194px;
    }
    .article-list .article-list-item {
        height: 49px;
    }
    .update-news-body-scroll {
        height: 380px;
    }
    .link-area .image-area {
        text-align: center;
    }
    .article-list-item .image-area img {
        height: 49px;
        width: auto;
    }
    .article-list-item .text-area {
        font-size: 14px;
    }
    .hero-news-short-img {
        height: 175px;
    }
    .hero-news-short {
        height: 250px;
    }
    .updated-news-style-2 {
        height: 403px;
    }
    .article-list-item {
        height: 57px;
    }
    .hero-news-short-play-img {
        height: 176px;
    }
    .img-wrapper,
    .img-wrapper2 {
        height: 230px;
    }
    .footer-border-image {
        border: 0px solid;
    }
    .footer-full-fst {
        height: auto;
    }
    .footer-full-editor {
        text-align: center;
    }
    .category-news-title h5 {
        display: none;
    }
    .category-news-img {
        flex: 0 0 160px;
        width: 160px;
    }
    .menu-top-cross {
        right: -45px;
    }
    .header-user {
        margin-top: 45px;
    }
}

@media (max-width: 764px) {
    /* .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 764px;
    } */
    .details-share-wh {
        background-size: 63%;
        background-position: 53%;
    }
    .height-130 {
        height: 40px;
    }

    .football-banner {
        height: 360px;
    }
    .fixture-item {
        width: 100%;
    }
    .football-point-tbl {
        margin-bottom: 12px;
    }
    .football-point-tbl table > tbody > tr > td > img {
        margin-bottom: 10px;
    }

    .football-point-tbl table > tbody > tr > td:first-child {
        text-align: center;
    }
    .football-point-tbl-title {
        font-size: 30px;
        line-height: 40px;
    }

    .fixture-item:nth-child(1) {
        border-right: unset;
    }

    .fixture-item:nth-child(2) {
    }

    .fixture-item:nth-child(3) {
        border-right: unset;
        padding-bottom: 0;
        border-top: unset;
    }
    .fixture-item:nth-child(4) {
        padding-bottom: 0;
        border-top: unset;
        border-bottom: unset;
    }
    .fixture-item:last-child {
        border-bottom: unset;
    }

    .football-point-tbl table {
        width: 100%;
    }

    .football-point-tbl table > thead:nth-child(2) {
        background-color: unset;
    }
    .football-point-tbl table > thead > tr > th {
        font-family: 'SolaimanLipi';
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        color: #111111;
        padding: 10px 10px;
        text-align: center;
        width: 14.28%;
    }

    .football-point-tbl table > thead > tr > th:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        text-align: left;
        width: 14.28%;
    }

    .football-point-tbl table > thead > tr > th:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .football-point-tbl table > tbody > tr {
        border-bottom: unset;
    }
    .football-point-tbl table > tbody > tr > td {
        font-family: 'SolaimanLipi';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #111111;
        padding: 10px 10px;
        text-align: center;
    }

    .football-point-tbl table > thead > tr > th > img {
        width: 40px;
        height: 25px;
        margin-right: 16px;
        border: 1px solid #fff0bb;
        border-radius: 4px;
    }
    .football-point-tbl table > tbody > tr > td:first-child {
        font-size: 20px;
        line-height: 20px;
        text-align: left;
    }
    .football-fixture-title {
        background-image: url('../images/HeaderPageBannerMobile.svg');
        margin-bottom: 10px;
    }
    .fixture-item-gap-design {
        display: none;
    }
    .fixture-item {
        border-bottom: 1px solid #ffd0d0;
    }
    .fixture-item:last-child {
        border-bottom: unset;
    }
    .fixture-item-country > h4 {
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 0;
    }
    .fixture-item-country > h5 {
        margin-bottom: 0;
        font-size: 18px;
    }
    .fixture-item-country > img {
        margin-right: 10px;
        margin-left: 10px;
        width: 30px;
        height: 22px;
        border-radius: 3px;
    }
    .football-fixture-breadcumb {
        margin-bottom: 10px;
    }
    .football-point-tbl-title-img {
        width: 30px !important;
        height: 22px !important;
        border-radius: 3px !important;
        font-size: unset !important;
        line-height: unset !important;
    }
    .football-fixture-tab > button {
        width: 50%;
        height: 40px;
        margin-bottom: 15px;
    }
    .fixture-item-c:nth-child(3) {
        border-bottom: unset;
    }
    .football-fixture-body-title {
        font-size: 16px;
    }

    .item-country p {
        text-align: left;
        padding-left: 10px;
    }
    .col-510 {
        flex: 100% !important;
        max-width: 100% !important;
    }
    .col-300 {
        flex: 100% !important;
        max-width: 100% !important;
    }
    .col-840 {
        flex: 100% !important;
        max-width: 100% !important;
    }
    .col-300-1 {
        flex: 100% !important;
        max-width: 100% !important;
    }
    .header-fake-height {
        height: 76px;
    }
    .header-border-higher {
        width: calc(100%);
        position: fixed;
        top: 0;
        z-index: 1000;
        background-color: #ffffff;
    }
    .header-logo-align {
        text-align: center !important;
    }
    .header-top {
        height: 75px !important;
    }
    .header-top-logo2 > img {
        width: 130px !important;
    }
    .header-more-icon {
        position: inherit;
        top: 25%;
    }
    .header-more-icon img {
        position: relative;
        top: 10px;
    }

    .header-user {
        height: auto !important;
        position: inherit;
        top: 25%;
    }
    .header-user-img {
        margin-top: 15px;
        height: 30px !important;
        width: 30px !important;
    }
    .header-border {
        height: 70px;
    }
    .hero-news-short-play-title {
        font-size: 20px !important;
        line-height: 30px !important;
        -webkit-line-clamp: inherit !important;
    }
    .hero-news-short-play-detail {
        font-size: 17px !important;
        color: #666666 !important;
        line-height: 26px !important;
        -webkit-line-clamp: 3 !important;
        height: 52px !important;
    }
    .hero-news-body > img {
        height: 260px !important;
    }
    .hero-news-short {
        height: auto !important;
        overflow: hidden;
        padding-bottom: 15px;
    }
    .hero-news-short-img {
        height: auto !important;
        max-height: 80% !important;
    }
    .hero-news-short-play-img {
        max-height: 80% !important;
        height: auto !important;
    }
    .hero-news-short-play-img > img {
        height: 100% !important;
    }
    .hero-news-short-title {
        height: auto !important;
        font-size: 20px !important;
        line-height: 30px !important;
        overflow: hidden !important;
        -webkit-line-clamp: initial !important;
    }
    .hero-news-short-title2 {
        height: auto !important;
        font-size: 15px !important;
        line-height: 20px !important;
        overflow: hidden !important;
        -webkit-line-clamp: 2 !important;
    }
    .search-page-sidebar > .accordion {
        display: none;
    }
    .search-page-sidebar-accordion {
        display: block !important;
    }
    .search-page-sidebar-title > button {
        display: flex;
        font-size: small;
        font-weight: 600;
    }
    .search-page-sidebar > .accordion > .card:last-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
        margin-bottom: 30px;
    }
    .search-cancel-btn {
        border: none !important;
        color: red;
        display: none;
        float: right;
        margin-left: 10px;
    }

    .header-search-group-side {
        width: 100% !important;
    }
    .header-search-text-input-side {
        width: 90% !important;
    }
    /* .update-news-body {
        width: 100% !important;
    } */
    .cat-news-short-img-detail {
        height: 162px !important;
    }
    .category-hero-news-short {
        height: auto !important;
    }
    .category-news-img {
        width: 160px;
    }
    .category-news-img > img {
        height: auto !important;
    }
    .category-news-title > h4 {
        font-size: 17px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        padding: 0px;
    }
    .category-news-title > h5 {
        font-size: 14px !important;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        padding-left: 0px !important;
    }
    .category-news-f-time {
        font-size: 12px !important;
    }
    .related-news-box-img {
        height: 82px !important;
    }
    .updated-news-2 {
        height: auto !important;
    }
    .update-news-body-img {
        height: auto !important;
    }
    .update-news-body-img > img {
        width: 100% !important;
    }
    .update-news-top-bottom-margin-auto {
        margin-top: auto;
        margin-bottom: auto;
    }
    .details-social {
        float: left !important;
    }
    .details-comment-sub {
        padding-left: 30px;
    }
    .details-comment-group {
        padding-left: 20px;
    }
    .bottom-category-news {
        height: auto !important;
    }
    .bottom-news-body {
        height: auto !important;
    }
    .bottom-news-short {
        height: auto !important;
    }
    .bottom-news-short-img {
        max-height: 80% !important;
        height: auto !important;
    }
    .bottom-news-short-img > img {
        height: 100% !important;
    }
    .footer-full-menu {
        min-height: auto !important;
        border: none !important;
    }
    .footer-full-fst {
        height: 365px !important;
    }
    .footer-full-share > ul {
        text-align: center !important;
    }
    .footer-full-editor {
        text-align: center !important;
    }
    .footer-full-app-download {
        float: none !important;
    }
    .footer-full-all-right {
        text-align: center;
    }
    .floating-button {
        margin-right: 15px !important;
        bottom: 30px !important;
    }

    .floating-button-day {
        margin-right: 15px !important;
        bottom: 30px !important;
        width: 65px !important;
        height: 65px !important;
    }
    .footer-add-modal-content {
        height: auto;
        margin-bottom: 25px;
    }
    .footer-add-close {
        font-size: 12px;
        width: 20px;
        height: 20px;
    }
    .footer-add-img {
        height: auto;
    }
    .lineUp {
        font-size: 15px;
        padding-top: 0px;
    }
    .tv-style {
        height: 28px !important;
    }
    .tv-style .title {
        width: 22% !important;
        line-height: 30px !important;
        font-size: 13px !important;
        padding-top: 0px !important;
    }
    .tv-style .text marquee {
        width: 78% !important;
        line-height: 30px !important;
    }
    .modal-header > .close {
        position: relative !important;
        top: 8px !important;
        color: red;
        /*padding: 1px 2px 4px 8px; */
        padding: none !important;
        margin-right: 15px;
    }
    .button.close {
        padding: none !important;
    }
    .update-news-body-scroll {
        height: auto !important;
    }
    .updated-news-style-2 {
        height: auto !important;
    }
    .bottom-news-body-img {
        /* margin-left: 15px !important; */
        height: 60px !important;
    }
    .home-category-news-title > h5 {
        left: 6px !important;
        font-size: 12px !important;
    }
    .footer-full {
        /* height: 253px; */
        margin-bottom: 28px !important;
    }
    .hero-news-body {
        height: auto !important;
    }
    .tz-gallery {
        height: auto !important;
    }
    .mujib-year {
        top: auto;
    }
    .hero-news-body .image-area {
        height: auto !important;
        max-height: 80%;
    }
    .t20-ads {
        display: none !important;
    }
    .t20-ads.sm {
        display: block !important;
    }
    .hero-news-body-title.link-title {
        -webkit-line-clamp: unset !important;
    }
    .details-reporter-img {
        margin-left: -6px !important;
        height: 53.31px;
    }
    .details-share-box {
        width: 370px;
        right: inherit !important;
        left: 8px;
        z-index: 1000 !important;
    }
    .details-share-box::before {
        right: inherit !important;
        left: 154px;
    }
    .details-share-box-tr > td {
        padding-right: 5px !important;
    }
    .details-share-box-tr > td > span {
        font-size: 12px !important;
        padding-left: 4px !important;
    }
    table.details-share-box-table td > button > span {
        white-space: nowrap;
        font-size: 13px;
    }
    .share-copy-link {
        top: -17px;
    }
    .img-wrapper {
        height: 150px !important;
    }
    .img-wrapper-m {
        height: 150px !important;
    }
    .inner-p-7 {
        padding-right: 15px !important;
    }
    .inner-p-0 {
        padding-left: 15px !important;
    }
    .inner-overlay2 {
        width: 85% !important;
    }
    .inner-overlay3 {
        width: 83% !important;
        left: unset !important;
    }
    .inner-overlay4 {
        width: 86% !important;
        left: unset !important;
    }
    .inner-overlay5 {
        width: 85% !important;
        left: unset !important;
    }
    .inner-overlay {
        width: 100% !important;
        right: unset !important;
    }
    .tz-gallery-img-title {
        font-size: 12px !important;
    }

    .add-nine-show {
        display: block;
    }
    .header-top-logo2 > img {
        margin: 10px 0;
    }
    .footer-border-image {
        border: 0px solid;
    }
    .category-news-title h5 {
        display: none;
    }
    .category-news-img {
        flex: 0 0 160px;
        width: 160px;
    }
    .hero-news-short-img-detail {
        height: auto;
    }
    .cat-news-short-img-detail {
        height: auto !important;
    }
    .category-news-img {
        flex: 0 0 110px;
        width: 110px;
    }
    .category-news-f-time {
        margin-bottom: 0;
    }
    .fb-comment-box {
        padding: 0 0;
    }
    .scroll-to-top.animate.show {
        bottom: 90px;
        right: 25px;
    }
    .menu-top-cross {
        right: -25px;
    }
    .details-reporter-info {
        margin-bottom: 0;
    }
    .facebook-provider {
        padding-left: 25px;
    }
    .special-news-title {
        height: auto;
    }
    .sidebar-category-news-title-edu {
        margin-bottom: 0px;
    }
    .sidebar-add-20 > a > img {
        width: 100% !important;
    }
    .modal-responsive-video {
        width: 100%;
        height: 80%;
    }
    .lb-sliding {
        overflow-x: unset;
        overflow-y: unset;
    }
    .lb-sliding > figure > figcaption {
        width: 100%;
    }
    .lb-navigator-left img {
        width: 30px;
    }
    .lb-navigator-right img {
        width: 30px;
    }
    .notification-set-window-modal {
    }

    button.close span:first-child {
        top: 1px !important;
        padding-right: -1px;
    }
    .archive-back-btn {
        width: 68px;
        height: 30px;
    }
    .archive-back-btn a {
        font-size: 12px;
    }
    .archive-back-btn a img {
        width: 12px;
    }
    .archive-title {
        font-size: 12px;
        padding: 0 20px;
    }
    .header-usern-notification {
        margin-top: 20px;
        margin-right: 8px;
    }
    .details-share-box-modal {
        height: 225px;
        width: 92%;
        margin-top: -337px;
        right: 11px;
    }
    .details-share-box-modal::after {
        right: 14px;
    }
    /* Sidebar Menu Section*/
    .sidebar-custom-width {
        width: 94% !important;
        min-width: 250px !important;
    }
    .sidebar-custom-width > .modal-content {
        box-shadow: 0 3px 12px 0 rgb(0 0 0 / 40%);
    }
    .sidebar-menu {
        width: 100% !important;
        min-width: 100% !important;
        top: 70px !important;
        position: fixed !important;
        transition: unset !important;
        border-top: 1px solid #e6e6e6;
    }
    .sidebar-menu::-webkit-scrollbar {
        display: none;
    }

    .sidebar-menu {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .sidebar-menu ::-webkit-scrollbar {
        display: none;
    }
    .sidebar-menu-time {
        font-family: 'SolaimanLipi';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: rgba(17, 17, 17, 0.7);
        margin-bottom: 10px;
        padding-left: 4px;
    }
    .sidebar-menu > .modal-content .modal-body {
        padding: 10px;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .sidebar-menu-list {
        display: flex;
        width: 100%;
    }
    .sidebar-menu-ul {
        padding-left: 0;
        width: 100%;
    }
    .sidebar-menu-ul li {
        padding: 8px 0;
        list-style: none;
        border-bottom: 1px solid #e6e6e6;
    }
    .sidebar-menu-ul li:last-child {
        border-bottom: none;
    }
    .sidebar-menu-ul li a {
        font-family: 'SolaimanLipi';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        color: #000000;
        mix-blend-mode: normal;
        opacity: 0.9;
    }
    .sidebar-share {
        padding-left: 20px;
        text-align: center;
    }
    .sidebar-share h5 {
        font-family: 'SolaimanLipi';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: #000000;
    }
    .sidebar-share ul {
        padding-left: 0;
    }
    .sidebar-share ul li {
        display: inline-block;
        margin-right: 20px;
    }
    .header-menu-mobile {
        width: 100%;
        height: 42px;
        border-bottom: 1px solid #c8c9ca;
        border-top: 1px solid #c8c9ca;
    }
    .header-menu-mobile > .container {
        height: 100%;
    }
    .mobile-sort-menu-main {
        display: flex;
        flex-direction: row;
        height: 100%;
    }
    .mobile-sort-menu {
        overflow: auto;
        white-space: nowrap;
        display: block;
        position: relative;
        height: 100%;
    }
    .mobile-sort-menu:first-child {
        width: 170px;
    }
    .mobile-sort-menu ul {
        overflow: scroll;
        padding-left: 0;
        margin-bottom: 0;
        height: 100%;
    }
    .mobile-sort-menu ul li {
        display: inline-flex;
        list-style: none;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin-right: 20px;
    }
    .mobile-sort-menu ul li a {
        display: inline-block;
        font-family: 'SolaimanLipi';
        font-style: normal;
        color: #333;
        text-align: center;
        /* padding: 8px 10px 8px 10px; */
        text-decoration: none;
        font-size: 16px;
        font-weight: 700;
    }
    .mobile-sort-menu .menu-view-active {
        border-bottom: 3px solid var(--color-header-menu-active);
        height: 100%;
    }
    .mobile-sort-menu::-webkit-scrollbar {
        display: none;
    }

    .mobile-sort-menu {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .mobile-sort-menu ::-webkit-scrollbar {
        display: none;
    }
    .mobile-sort-menu > ul > li > img {
        display: none;
    }
    .mobile-sort-menu-fixed-li {
        margin-right: unset !important;
    }
    .header-menu-mobile.position-higher {
        width: 100%;
        position: fixed;
        top: 0;
        z-index: 1000;
        border-top: 0;
    }
    .position-higher > .container > .mobile-sort-menu-main > .mobile-sort-menu > ul > li > img {
        display: inline-flex;
    }
    .position-higher > .container > .mobile-sort-menu-main > .mobile-sort-menu:first-child {
        width: 275px;
    }
    .recent-share-tr > td > button > span {
        font-size: 10px !important;
    }
    .raw-html-embed > iframe {
        /* height: 200px !important; */
    }
}

@media print {
    @page {
        margin-bottom: 20px;
    }
    .not-print-class {
        display: none;
    }
    /* .header-top {
    height: 140px !important;
  }
  .header-top-logo2 > img {
    width: 230px !important;
  } */
    .print-footer {
        display: block;
    }
    .header-menu-mobile {
        display: none;
    }
    .header-logo-align {
        text-align: left !important;
    }
    .home-category-news-title-sub > a {
        position: relative;
        left: 0px;
        top: 12px;
        font-family: SolaimanLipi;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        color: var(--color-white);
    }
    .details-social {
        display: none;
    }
    figure > img {
        display: block;
    }
    .scroll-to-top {
        display: none;
    }
    .home-category-news-title {
        background: none;
    }
    .home-category-news-title-green {
        background: none;
    }
    .home-category-news-title-sub > a {
        color: rgba(0, 0, 0, 0.521);
    }
    .bottom-modal-content {
        display: none;
    }
    .hide-print {
        display: none;
    }
    .footer-full-editor {
        margin-left: 20px;
    }
}
